export const iconsMetaData: Record<string, string> = {
    "rioglyph-academic-cap": "education graduation learning school",
    "rioglyph-addressbook": "contacts phonebook directory list",
    "rioglyph-air-pay": "contactless payment wireless transaction",
    "rioglyph-am": "morning time clock period",
    "rioglyph-angle-double-down": "arrow navigation dropdown",
    "rioglyph-angle-double-left": "arrow backward rewind previous",
    "rioglyph-angle-double-right": "arrow forward next skip",
    "rioglyph-angle-double-up": "arrow scroll top navigation",
    "rioglyph-antenna": "signal communication wireless",
    "rioglyph-api": "integration development backend code",
    "rioglyph-archive": "storage backup save history",
    "rioglyph-area-chart": "graph statistics analytics data",
    "rioglyph-area-in": "entry zone boundary",
    "rioglyph-area-out": "exit zone boundary",
    "rioglyph-arrow-down": "download navigate lower",
    "rioglyph-arrow-left": "back return previous",
    "rioglyph-arrow-right": "next forward move",
    "rioglyph-arrow-up": "upload ascend navigate",
    "rioglyph-axle": "wheel vehicle transport",
    "rioglyph-ban-circle": "prohibited restricted stop forbidden",
    "rioglyph-battery-level-empty": "low power charge warning",
    "rioglyph-battery-level-full": "fully charged power",
    "rioglyph-battery-level-low": "low charge warning",
    "rioglyph-beacon": "signal tracking positioning gps trailer",
    "rioglyph-book": "reading literature knowledge",
    "rioglyph-bookable-poi": "reservation location place",
    "rioglyph-bookmark-square": "save favorite highlight",
    "rioglyph-bookmark": "favorite highlight saved",
    "rioglyph-border-crossing": "customs checkpoint travel",
    "rioglyph-brake": "stop safety vehicle",
    "rioglyph-building": "architecture office home structure",
    "rioglyph-bus-baseline": "public transport commute",
    "rioglyph-bus-breakdown": "vehicle failure stop",
    "rioglyph-bus": "transport public commute travel",
    "rioglyph-businessman": "corporate work professional",
    "rioglyph-calendar-parking": "reservation schedule booking",
    "rioglyph-calendar-plus": "add event schedule",
    "rioglyph-calendar-today": "current date reminder",
    "rioglyph-calendar": "schedule date events",
    "rioglyph-camera": "photo video capture",
    "rioglyph-car-baseline": "vehicle transport automobile",
    "rioglyph-car-wash": "clean vehicle service",
    "rioglyph-car": "automobile transport drive",
    "rioglyph-card-hotel": "access key entry",
    "rioglyph-card-reader": "payment swipe scanner",
    "rioglyph-cards-add": "new add payment banking",
    "rioglyph-cards-grid": "layout display options",
    "rioglyph-cards-list": "menu display items",
    "rioglyph-cards-table": "data information structured",
    "rioglyph-carrier": "logistics transport shipment",
    "rioglyph-chat": "message communication talk",
    "rioglyph-check-badge": "verified approved trusted",
    "rioglyph-check-shield": "security verified safe",
    "rioglyph-checkbox-checked": "selected confirmed approved",
    "rioglyph-checkbox": "option select choice",
    "rioglyph-checkboxes": "multiple selection options",
    "rioglyph-chevron-down": "arrow expand dropdown lower",
    "rioglyph-chevron-left": "arrow previous back rewind",
    "rioglyph-chevron-right": "arrow next forward skip",
    "rioglyph-chevron-up": "arrow collapse expand move up",
    "rioglyph-circle-stack": "layers data archive",
    "rioglyph-click": "press interaction tap",
    "rioglyph-climate-control": "temperature settings comfort",
    "rioglyph-clipboard": "notes copy list task",
    "rioglyph-cloud-download": "backup retrieve save storage",
    "rioglyph-cloud-not-saved": "unsaved warning lost data",
    "rioglyph-cloud-upload": "store sync backup",
    "rioglyph-cloud": "online network storage",
    "rioglyph-coasting": "glide drive motion smooth",
    "rioglyph-code-braces": "development programming syntax",
    "rioglyph-code-brackets": "html css coding",
    "rioglyph-cog": "settings gear adjust",
    "rioglyph-color-swatch": "palette design theme",
    "rioglyph-comment": "message chat feedback",
    "rioglyph-compare": "difference evaluate review",
    "rioglyph-component-custom-recurrent": "repeat automation process",
    "rioglyph-component-custom": "personalized settings modular",
    "rioglyph-component": "element part module",
    "rioglyph-construction": "building work development",
    "rioglyph-cookie": "data storage privacy",
    "rioglyph-cooling": "air conditioning temperature",
    "rioglyph-cost-efficency": "savings budget expense",
    "rioglyph-cruise-control": "auto speed drive assist",
    "rioglyph-csv": "spreadsheet data export file",
    "rioglyph-currency-euro": "money finance exchange",
    "rioglyph-damages": "loss accident insurance",
    "rioglyph-dangerousgoods": "hazard shipping risk",
    "rioglyph-dashboard": "control panel overview",
    "rioglyph-delivery-completed": "tms transport shipment success package received",
    "rioglyph-delivery-error": "tms transport failed shipment issue",
    "rioglyph-delivery-late": "tms transport delayed shipping package",
    "rioglyph-delivery-on-track": "tms transport on time scheduled shipping",
    "rioglyph-delivery-warning": "tms transport issue caution shipment",
    "rioglyph-delivery": "tms transport shipping package",
    "rioglyph-desktop": "computer workstation pc",
    "rioglyph-detail-view-info": "expanded details insights",
    "rioglyph-detail-view": "information overview display",
    "rioglyph-direction": "navigate route gps",
    "rioglyph-document-out": "export file pdf",
    "rioglyph-document": "file text report",
    "rioglyph-download": "save retrieve file",
    "rioglyph-drag-n-drop": "move reposition reorder arrange grab",
    "rioglyph-drive-history": "trip log past journeys vehicle tracking",
    "rioglyph-driver-card": "driver id license authentication",
    "rioglyph-driver": "person operator vehicle chauffeur",
    "rioglyph-drivercard-in": "entry login access verification",
    "rioglyph-drivercard-out": "exit logout removal verification",
    "rioglyph-duplicate": "copy clone replicate duplicate",
    "rioglyph-earphone": "headphones audio music sound",
    "rioglyph-empty": "blank null missing unavailable",
    "rioglyph-engine": "motor vehicle power mechanical",
    "rioglyph-envelope": "mail email message letter communication",
    "rioglyph-erase": "delete remove clear wipe",
    "rioglyph-error-sign": "warning issue problem alert",
    "rioglyph-euro-note": "currency money euro finance",
    "rioglyph-exclamation-sign": "alert warning important notice",
    "rioglyph-exclamation": "caution urgent attention",
    "rioglyph-eye-close": "hide invisible privacy security",
    "rioglyph-eye-option": "visibility show settings customization",
    "rioglyph-face-frown": "sad disappointed unhappy reaction emoji smiley",
    "rioglyph-face-neutral": "expression normal indifferent reaction emoji smiley",
    "rioglyph-face-smile": "happy joy pleased positive emoji smiley",
    "rioglyph-facetime-video": "call conference communication camera",
    "rioglyph-factory": "industry production manufacturing plant",
    "rioglyph-file-signature": "document sign approve verify",
    "rioglyph-files": "documents archive storage",
    "rioglyph-fill": "complete input apply paint",
    "rioglyph-filling-e-station": "charging electric vehicle energy",
    "rioglyph-filling-station": "gas petrol refuel fuel",
    "rioglyph-filter-active": "search refine results active",
    "rioglyph-filter": "sort refine selection search",
    "rioglyph-fingerprint": "biometric security authentication identity",
    "rioglyph-finish": "complete done end task",
    "rioglyph-fire": "flame heat emergency danger",
    "rioglyph-flag": "marker country nation highlight",
    "rioglyph-flash": "light electricity thunder bolt",
    "rioglyph-folder-closed": "directory storage document private",
    "rioglyph-folder-open": "directory storage document visible",
    "rioglyph-fuel-diesel": "gas refuel transport energy",
    "rioglyph-fuel-electric": "charging vehicle power eco",
    "rioglyph-fuel-gas": "petrol fuel station energy",
    "rioglyph-fuel-hydrogen": "eco fuel cell refill energy",
    "rioglyph-fullscreen": "expand enlarge maximize view",
    "rioglyph-geofence": "location boundary gps tracking",
    "rioglyph-globe": "world internet international global",
    "rioglyph-group": "people team organization users",
    "rioglyph-hand-down": "gesture direction point lower",
    "rioglyph-hand-left": "gesture direction point back",
    "rioglyph-hand-right": "gesture direction point forward",
    "rioglyph-hand-up": "gesture direction point raise",
    "rioglyph-handshake": "agreement partnership deal contract",
    "rioglyph-hash": "number tag identifier code",
    "rioglyph-heart": "love favorite like emotion",
    "rioglyph-heating": "temperature warm system climate",
    "rioglyph-home-sign": "address property real estate",
    "rioglyph-home": "house residence living building",
    "rioglyph-hour-glass": "time wait countdown sand",
    "rioglyph-id": "identity card verification badge",
    "rioglyph-inbox-in": "email receive message notification",
    "rioglyph-inbox-out": "email send outgoing mail",
    "rioglyph-inbox-stack": "messages communication storage",
    "rioglyph-inbox": "mailbox messages email notifications",
    "rioglyph-info-sign": "information help guidance knowledge",
    "rioglyph-info": "details data explanation",
    "rioglyph-key": "security access unlock passcode",
    "rioglyph-kickdown": "accelerate boost power vehicle",
    "rioglyph-language": "translation multilingual speech",
    "rioglyph-layer-pois": "map locations markers places",
    "rioglyph-layer": "stack levels structure organization",
    "rioglyph-light-bulb": "idea innovation brightness",
    "rioglyph-line-chart": "graph statistics analytics data",
    "rioglyph-link": "connection url chain reference",
    "rioglyph-load-unload": "logistics delivery shipment",
    "rioglyph-load": "transport goods cargo truck",
    "rioglyph-location-arrow": "gps navigation find direction",
    "rioglyph-lock-open": "unlocked security access safe",
    "rioglyph-lock": "locked security safe private",
    "rioglyph-log-in": "access entry authentication login",
    "rioglyph-logout": "exit sign out disconnect",
    "rioglyph-looking-glass-man": "search user inspect person",
    "rioglyph-looking-glass": "magnify zoom find search",
    "rioglyph-maintenance-components": "repair fix settings tools",
    "rioglyph-map-location": "gps direction pin navigation",
    "rioglyph-map-marker": "location pin place gps",
    "rioglyph-map": "navigation geography world places",
    "rioglyph-megaphone": "announcement loudspeaker public address",
    "rioglyph-menu-hamburger": "navigation sidebar options",
    "rioglyph-merge": "combine integrate join union",
    "rioglyph-milage": "distance fuel efficiency odometer",
    "rioglyph-minus-light": "decrease subtract remove minimal",
    "rioglyph-minus-sign": "negative reduce delete",
    "rioglyph-minus": "subtract decrease remove",
    "rioglyph-missing": "lost unavailable not found",
    "rioglyph-mode-dark-light": "theme night day toggle",
    "rioglyph-more": "options expand additional",
    "rioglyph-new-window": "open external link pop-up",
    "rioglyph-newspaper": "news articles media",
    "rioglyph-notification": "alert message reminder",
    "rioglyph-number-1": "one first priority",
    "rioglyph-number-2": "two second ranking",
    "rioglyph-number-3": "three third placement",
    "rioglyph-off": "power disabled inactive",
    "rioglyph-oil-can": "lubrication maintenance engine",
    "rioglyph-ok-circle": "approved success confirmed",
    "rioglyph-ok-sign": "checkmark confirm positive",
    "rioglyph-ok": "yes valid verified",
    "rioglyph-onboarding": "setup introduction start",
    "rioglyph-ongoing": "progress active continuing",
    "rioglyph-option-horizontal": "settings choices selection",
    "rioglyph-option-vertical": "preferences list customization",
    "rioglyph-order": "sequence arrangement structured",
    "rioglyph-palette-broken": "color error damaged",
    "rioglyph-palette": "design colors theme",
    "rioglyph-paper-clip": "attachment document file",
    "rioglyph-parcel-broken": "damaged package shipping",
    "rioglyph-parcel": "package delivery shipping",
    "rioglyph-parking": "car stop vehicle lot",
    "rioglyph-pause-circle": "stop hold break",
    "rioglyph-pause": "halt freeze temporary stop",
    "rioglyph-pdf-file": "document portable format",
    "rioglyph-pencil-square": "edit modify update",
    "rioglyph-pencil": "write draw sketch",
    "rioglyph-phone": "call contact communication",
    "rioglyph-pictures": "images photos gallery",
    "rioglyph-pin-range": "location distance area",
    "rioglyph-pin": "location marker place",
    "rioglyph-play-circle": "start video audio media",
    "rioglyph-play": "begin start media",
    "rioglyph-plugged-off": "unplug disconnect power",
    "rioglyph-plugged-on": "connected power charging",
    "rioglyph-plus-light": "increase add expand",
    "rioglyph-plus-sign": "positive addition",
    "rioglyph-plus": "add more expand",
    "rioglyph-pm": "afternoon evening time",
    "rioglyph-poi": "point of interest location",
    "rioglyph-polygon": "shape boundary area",
    "rioglyph-position": "location placement coordinate",
    "rioglyph-print": "document paper hardcopy",
    "rioglyph-progress-ongoing": "process running active",
    "rioglyph-progress-remaining": "incomplete pending left",
    "rioglyph-pto-off": "power take-off disabled",
    "rioglyph-pto-on": "power take-off engaged",
    "rioglyph-pushpin": "pin marker location",
    "rioglyph-puzzle": "challenge problem-solving pieces",
    "rioglyph-pylon": "tower structure electricity",
    "rioglyph-qr-code": "barcode scan digital",
    "rioglyph-question-sign": "help assistance inquiry",
    "rioglyph-question": "faq ask information",
    "rioglyph-record": "audio video capture",
    "rioglyph-refresh": "reload update reset",
    "rioglyph-remove-circle": "delete cancel reject",
    "rioglyph-remove-sign": "close dismiss exit",
    "rioglyph-remove": "delete subtract clear",
    "rioglyph-repeat": "redo replay loop",
    "rioglyph-resize-full": "expand maximize enlarge",
    "rioglyph-resize-horizontal": "adjust width stretch",
    "rioglyph-resize-small": "minimize shrink reduce",
    "rioglyph-resize-vertical": "adjust height scale",
    "rioglyph-retrofit": "upgrade modify improve",
    "rioglyph-retweet": "reshare repost social media",
    "rioglyph-revert": "undo restore previous",
    "rioglyph-rio-marker": "map pin navigation",
    "rioglyph-rio": "branding logo company",
    "rioglyph-road-restrictions": "traffic limits block",
    "rioglyph-road": "street highway travel",
    "rioglyph-robot": "automation ai machine",
    "rioglyph-rocket": "launch space fast",
    "rioglyph-role-management": "permissions access users",
    "rioglyph-route-option": "directions alternative path",
    "rioglyph-route-view": "map navigation tracking",
    "rioglyph-route": "path journey gps",
    "rioglyph-ruler": "measurement scale distance",
    "rioglyph-save": "store backup keep",
    "rioglyph-scale": "size measurement balance",
    "rioglyph-scan": "barcode qr detect",
    "rioglyph-scissors": "cut trim edit",
    "rioglyph-search-list": "find results filter",
    "rioglyph-search-truck": "vehicle gps locate",
    "rioglyph-search": "find lookup query",
    "rioglyph-send": "submit deliver transfer",
    "rioglyph-series": "sequence order steps",
    "rioglyph-server-stack": "data center cloud hosting",
    "rioglyph-settings": "preferences gear configuration options",
    "rioglyph-share-alt": "send distribute forward social",
    "rioglyph-share-nodes": "connect network distribute social",
    "rioglyph-share": "send forward distribute",
    "rioglyph-ship": "boat vessel cargo transport",
    "rioglyph-shopping-bag": "purchase store retail commerce",
    "rioglyph-shopping-cart": "buy purchase checkout basket",
    "rioglyph-signature": "autograph approve document sign",
    "rioglyph-slope-down-max": "steep decline gradient",
    "rioglyph-slope-down-min": "gentle decline gradient",
    "rioglyph-slope-up-max": "steep incline gradient",
    "rioglyph-slope-up-min": "gentle incline gradient",
    "rioglyph-sort-by-attributes-alt": "arrange order filter",
    "rioglyph-sort-by-attributes": "organize rank sequence",
    "rioglyph-sort": "order arrange prioritize",
    "rioglyph-sparkles": "glow shine effect magic",
    "rioglyph-speed": "velocity fast quick performance",
    "rioglyph-sphere": "globe round shape world",
    "rioglyph-spinner": "loading processing wait animation",
    "rioglyph-split-view": "dual screen compare layout",
    "rioglyph-stack": "layers pile grouped",
    "rioglyph-star-empty": "favorite like outline",
    "rioglyph-star": "favorite like highlight",
    "rioglyph-stars": "rating favorite review",
    "rioglyph-start": "begin initiate launch",
    "rioglyph-stats": "analytics data metrics graph",
    "rioglyph-status-available": "online active ready",
    "rioglyph-status-driving": "moving vehicle on the road",
    "rioglyph-status-resting": "paused stopped inactive",
    "rioglyph-status-working": "active engaged in task",
    "rioglyph-steering-wheel": "vehicle drive control",
    "rioglyph-stopover": "layover pause rest",
    "rioglyph-support": "help assistance customer service",
    "rioglyph-table-view": "grid data spreadsheet list",
    "rioglyph-tachograph-download": "log data report compliance",
    "rioglyph-tachograph": "vehicle log driving time",
    "rioglyph-tag": "label identifier marker",
    "rioglyph-tasks": "to-do list jobs assignments",
    "rioglyph-temperature": "heat climate weather degrees",
    "rioglyph-th-list": "items structure layout",
    "rioglyph-thumbs-down": "dislike reject negative feedback",
    "rioglyph-thumbs-up": "approve like positive feedback",
    "rioglyph-tickets": "event pass admission coupon",
    "rioglyph-time-alt": "clock timer schedule alternative",
    "rioglyph-time-cancle": "stop remove clock cancel",
    "rioglyph-time": "clock timer schedule",
    "rioglyph-tire": "wheel vehicle rubber traction",
    "rioglyph-tms": "transportation tracking logistics",
    "rioglyph-to-bottom": "navigate down end",
    "rioglyph-to-left": "navigate back previous",
    "rioglyph-to-right": "navigate forward next",
    "rioglyph-to-top": "navigate up start",
    "rioglyph-traffic-lights": "signal stoplight road safety",
    "rioglyph-trailer-baseline": "transport attach tow",
    "rioglyph-trailer": "cargo transport vehicle",
    "rioglyph-trailerposition": "placement logistics alignment",
    "rioglyph-transfer": "move shift relocate",
    "rioglyph-trash": "delete remove discard",
    "rioglyph-triangle-bottom": "direction down arrow",
    "rioglyph-triangle-left": "direction back previous arrow",
    "rioglyph-triangle-right": "direction next forward arrow",
    "rioglyph-triangle-top": "direction up arrow",
    "rioglyph-trophy": "award prize achievement",
    "rioglyph-truck-baseline": "transport freight delivery",
    "rioglyph-truck-breakdown": "malfunction vehicle repair",
    "rioglyph-truck-unit": "transport freight logistics",
    "rioglyph-truck": "freight transport logistics vehicle",
    "rioglyph-unlink": "disconnect break remove",
    "rioglyph-upload": "send transfer file",
    "rioglyph-user-add": "new member profile account",
    "rioglyph-user-group": "team people community members",
    "rioglyph-user-ok": "verified approved confirmed profile",
    "rioglyph-user-remove": "delete profile remove account",
    "rioglyph-user-sign-off": "logout exit disconnect",
    "rioglyph-user-sign": "account login identification",
    "rioglyph-user": "profile person account",
    "rioglyph-van-baseline": "vehicle transport delivery",
    "rioglyph-van": "transport cargo delivery vehicle",
    "rioglyph-volume": "sound audio speaker loudness",
    "rioglyph-wallet": "money finance payment",
    "rioglyph-warning-sign": "alert caution danger risk",
    "rioglyph-weather-cloudy": "forecast overcast grey sky",
    "rioglyph-weather-icy": "cold freeze slippery frost",
    "rioglyph-weather-overcast": "grey cloudy dull sky",
    "rioglyph-weather-raining": "rain shower precipitation",
    "rioglyph-weather-snowing": "snowflakes winter cold weather",
    "rioglyph-weather-stormy": "thunder lightning severe",
    "rioglyph-weather-sunny": "clear hot bright sunshine",
    "rioglyph-weather-thundering": "lightning storm thunder",
    "rioglyph-weather-windy": "breezy gust airflow",
    "rioglyph-weight": "mass measurement load scale",
    "rioglyph-width": "size dimension horizontal measurement",
    "rioglyph-wifi-off": "no signal internet disconnected",
    "rioglyph-wifi": "wireless internet connection network",
    "rioglyph-window": "frame display view screen",
    "rioglyph-workshop": "training repair hands-on session",
    "rioglyph-wrench": "tool repair fix maintenance",
    "rioglyph-xmas-raindeer": "holiday christmas festive",
    "rioglyph-xmas-santa": "christmas holiday gift festive"
};
