import React, {
    forwardRef,
    type ForwardRefExoticComponent,
    type HTMLProps,
    type PropsWithChildren,
    type RefAttributes,
    type SyntheticEvent,
    useRef,
    useState,
} from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

import { createButtonRipple } from '../../utils/buttonEffect';
import useMergeRefs from '../../hooks/useMergeRefs';
import type { ObjectValues } from '../../utils/ObjectValues';

export const STYLES_MAP = {
    DEFAULT: 'default',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    INFO: 'info',
    WARNING: 'warning',
    DANGER: 'danger',
    SUCCESS: 'success',
    MUTED: 'muted',
    MUTED_FILLED: 'muted-filled',
} as const;

// export for convenient usage on client side
export type BUTTON_STYLE = ObjectValues<typeof STYLES_MAP>;

export const VARIANTS_MAP = {
    VARIANT_LINK: 'link',
    VARIANT_LINK_INLINE: 'link-inline',
    VARIANT_OUTLINE: 'outline',
    VARIANT_ACTION: 'action',
} as const;

export type BUTTON_VARIANT = ObjectValues<typeof VARIANTS_MAP>;

export const SIZES_MAP = {
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
} as const;

export type BUTTON_SIZE = ObjectValues<typeof SIZES_MAP>;

/*
 *  ATTENTION: We're typing the onClick handler based on the value of `asToggle`.
 *
 *  It does not fully work here inside of this file, though. Therefore, we have to apply some explicit casts below.
 *  In code _using_ the Button component, however, the parameter type of the onClick handler must either be a boolean or
 *  a React.MouseEvent, respectively.
 */

type RegularButton = {
    /**
     * Use the button as a toggle button. The toggle state can be controlled via the `active` prop.
     * @default false
     */
    asToggle?: false;

    /**
     * Callback function triggered when clicking the button.
     * @param event The MouseEvent that triggered the click.
     */
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type ToggleButton = {
    /**
     * Use the button as a toggle button. The toggle state can be controlled via the `active` prop.
     * @default false
     */
    asToggle: true;

    /**
     * Callback function triggered when clicking the button.
     * @param value The new value of the toggle button.
     */
    onClick?: (value: boolean) => void;
};

export type ButtonProps = Omit<HTMLProps<HTMLButtonElement>, 'type' | 'onClick'> & {
    /**
     * Whether the button should be disabled.
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * Set the button toggled. Should be used in combination with "asToggle" prop.
     * @default false
     */
    active?: boolean;

    /**
     * Defines the type of the button. This may be used for form submit buttons.
     * @default 'button'
     */
    type?: 'button' | 'submit';

    /**
     * Use when the content of the button is an icon only to adapt the button spacing accordingly.
     * @default false
     */
    iconOnly?: boolean;

    /**
     * Adds right side spacing for an icon. This should be used when having navigation buttons
     * that use an icon on the right side.
     * @default false
     */
    iconRight?: boolean;

    /**
     * Optional rio-glyph icon name that comes in handy for icon only buttons for not adding
     * a span tag for the icon which reduces boilerplate code.
     */
    iconName?: string;

    /**
     * Defines whether the button text break into multiple lines when the button space exceeds.
     *
     * Multiline buttons should be used as exception only.
     * @default false
     */
    multiline?: boolean;

    /**
     * Defines whether the button takes up the full width of the parent element.
     * @default false
     */
    block?: boolean;

    /**
     * Sets the button style.
     * @default "default"
     */
    bsStyle?: BUTTON_STYLE;

    /**
     * Sets the button size.
     */
    bsSize?: BUTTON_SIZE;

    /**
     * Sets the button variant.
     */
    variant?: BUTTON_VARIANT;

    /**
     * Whether the "ripple" effect should be suppressed on this button.
     */
    noRippleEffect?: boolean;

    /**
     * Number of the index used for keyboard support.
     * @default 0
     */
    tabIndex?: number;

    /**
     * Additional classes to be set on the button element.
     */
    className?: string;
} & (RegularButton | ToggleButton);

type Props = PropsWithChildren<ButtonProps>;

// Define statics to be used as "Button.PRIMARY"
type ButtonType = ForwardRefExoticComponent<Props & RefAttributes<HTMLButtonElement>> & {
    DEFAULT: 'default';
    PRIMARY: 'primary';
    SECONDARY: 'secondary';
    INFO: 'info';
    WARNING: 'warning';
    DANGER: 'danger';
    SUCCESS: 'success';
    MUTED: 'muted';
    MUTED_FILLED: 'muted-filled';

    VARIANT_LINK: 'link';
    VARIANT_LINK_INLINE: 'link-inline';
    VARIANT_OUTLINE: 'outline';
    VARIANT_ACTION: 'action';

    XS: 'xs';
    SM: 'sm';
    MD: 'md';
    LG: 'lg';
};

const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const {
        active = false,
        disabled = false,
        asToggle = false,
        onClick = noop,
        bsStyle = 'default',
        bsSize,
        variant,
        iconOnly = false,
        iconName,
        iconRight = false,
        multiline = false,
        block = false,
        className = '',
        noRippleEffect = false,
        type = 'button',
        tabIndex = 0,
        children,
        ...remainingProps
    } = props;

    const [isToggled, setIsToggled] = useState(active);

    const btnRef = useRef<HTMLButtonElement>(null);

    const buttonRef = useMergeRefs(btnRef, ref);

    // Update internal toggle state when used as controlled component and outside toggle state changes
    // Note, using the usePrevious hook resulted in an endless loop, hence the useState here
    const [previousActive, setPreviousActive] = useState(active);
    if (active !== previousActive) {
        setIsToggled(active);
        setPreviousActive(active);
    }

    const handleClick = (event: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
        if (!noRippleEffect) {
            createButtonRipple(event.nativeEvent, event.currentTarget);
        }

        if (asToggle) {
            // Intercept click handler only for toggle button to update internal state and blur after click
            const newIsToggled = !isToggled;
            setIsToggled(newIsToggled);

            if (newIsToggled) {
                btnRef.current?.blur();
            }

            (onClick as (x: boolean) => void)(newIsToggled);
        } else {
            (onClick as (x: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void)(event);
        }
    };

    const buttonClassNames = classNames(
        'btn',
        `btn-${bsStyle}`,
        variant === VARIANTS_MAP.VARIANT_LINK && 'btn-link',
        variant === VARIANTS_MAP.VARIANT_LINK_INLINE && 'btn-link btn-link-inline',
        variant === VARIANTS_MAP.VARIANT_OUTLINE && 'btn-outline',
        variant === VARIANTS_MAP.VARIANT_ACTION && 'btn-action',
        bsSize && `btn-${bsSize}`,
        asToggle && 'btn-toggle',
        isToggled && 'active',
        iconOnly && 'btn-icon-only',
        iconRight && 'btn-icon-right',
        multiline && 'btn-multiline',
        block && 'btn-block',
        'btn-component',
        className
    );

    return (
        <button
            ref={buttonRef}
            type={type}
            {...remainingProps}
            className={buttonClassNames}
            onClick={handleClick}
            disabled={disabled}
            tabIndex={tabIndex}
        >
            {iconName && <span className={`rioglyph ${iconName}`} />}
            {children}
        </button>
    );
}) as ButtonType;

Object.assign(Button, STYLES_MAP);
Object.assign(Button, VARIANTS_MAP);
Object.assign(Button, SIZES_MAP);

export default Button;
