import { useState } from 'react';
import omit from 'lodash/fp/omit';
import isEmpty from 'lodash/fp/isEmpty';
import classNames from 'classnames';

import TableToolbar from '../../../../../../src/TableToolbar';
import TableViewToggles, { type TableViewTogglesViewType } from '../../../../../../src/TableViewToggles';
import TableSearch from '../../../../../../src/TableSearch';
import TableCardsSorting from '../../../../../../src/TableCardsSorting';
import TableSettingsDialog from '../../../../../../src/TableSettingsDialog';
import SortDirection, { type SortDirectionType } from '../../../../../../src/SortDirection';
import SortArrows from '../../../../../../src/SortArrows';
import ButtonDropdown from '../../../../../../src/ButtonDropdown';
import NotFoundState from '../../../../../../src/NotFoundState';
import NoData from '../../../../../../src/NoData';
import { naturalSortByProperty } from '../../../../../../src/SortUtils';
import Button from '../../../../../../src/Button';

const defaultColumnOrder = ['vehicleId', 'name', 'vin', 'status', 'duration'];
const disabledColumns = ['vin'];

type ColumnLabel = {
    [key: string]: string;
};
const columnLabels: ColumnLabel = {
    vehicleId: 'Id',
    name: 'Name',
    vin: 'VIN',
    status: 'Status',
    duration: 'Duration',
};

type ColumnDetails = {
    [key: string]: number;
};

type ColumnDetailsMap = {
    [key: string]: ColumnDetails;
};

const demoColumnsDetails: ColumnDetailsMap = {
    vehicleId: {
        width: 60,
        defaultWidth: 60,
        maxWidth: 300,
    },
    name: {
        width: 200,
        defaultWidth: 200,
        maxWidth: 350,
    },
    // Note: 0 means auto width
    vin: {
        width: 0,
        defaultWidth: 0,
        maxWidth: 350,
    },
    status: {
        // Not defined as it will be defined when its used
        // width: 350,
        // defaultWidth: 0,
    },
};

type Vehicle = {
    vehicleId: number;
    name: React.ReactNode;
    vin: React.ReactNode;
    status: string;
    duration: string;
};

const vehicleList: Vehicle[] = [
    {
        vehicleId: 7354,
        name: 'Lorem',
        vin: '7354',
        status: 'Active',
        duration: '10h 30m',
    },
    {
        vehicleId: 233,
        name: 'Ipsum',
        vin: <NoData text='No data' />,
        status: 'Inactive',
        duration: '5h 15m',
    },
    {
        vehicleId: 895,
        name: <NoData text='No data' />,
        vin: '895',
        status: 'Active',
        duration: '8h 45m',
    },
    {
        vehicleId: 3456,
        name: <NoData text='No data' />,
        vin: '3456',
        status: 'Active',
        duration: '12h 00m',
    },
    {
        vehicleId: 2445,
        name: <NoData text='No data' />,
        vin: '2445',
        status: 'Active',
        duration: '7h 25m',
    },
    {
        vehicleId: 878,
        name: <NoData text='No data' />,
        vin: '878',
        status: 'Active',
        duration: '9h 10m',
    },
    {
        vehicleId: 2345,
        name: 'Lorem',
        vin: '2345',
        status: 'Active',
        duration: '11h 50m',
    },
    {
        vehicleId: 978,
        name: 'Ipsum',
        vin: <NoData text='No data' />,
        status: 'Inactive',
        duration: '6h 35m',
    },
    {
        vehicleId: 456,
        name: <NoData text='No data' />,
        vin: '456',
        status: 'Active',
        duration: '4h 55m',
    },
    {
        vehicleId: 4567,
        name: <NoData text='No data' />,
        vin: '4567',
        status: 'Active',
        duration: '10h 20m',
    },
];

const dummyActionOptions = [
    { value: 'Option 1', onSelect: () => {} },
    { value: 'Option 2', onSelect: () => {} },
    { value: 'Option 3', onSelect: () => {} },
    { divider: true },
    { value: 'Option 4', onSelect: () => {}, disabled: true },
];

const getSortDir = (sortDir: SortDirectionType, sortBy: string, previousSortBy: string) => {
    if (sortBy === previousSortBy) {
        return sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
    }
    return SortDirection.ASCENDING;
};

export type TableCommonDemoProps = {
    viewType: TableViewTogglesViewType;
};

const TableCommonDemo = (props: TableCommonDemoProps) => {
    const { viewType: externalViewType } = props;

    const [searchValue, setSearchValue] = useState('');
    const [sortBy, setSortBy] = useState('vehicleId');
    const [sortDir, setSortDir] = useState<SortDirectionType>(SortDirection.ASCENDING);
    const [showTableSettingsDialog, setShowTableSettingsDialog] = useState(false);
    const [columnOrder, setColumnOrder] = useState<string[]>(defaultColumnOrder);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const [columnsDetails, setColumnsDetails] = useState(demoColumnsDetails);
    const [viewType, setViewType] = useState(externalViewType || TableViewToggles.VIEW_TYPE_TABLE);

    const handleToggleTableSettingsDialog = () => setShowTableSettingsDialog(!showTableSettingsDialog);
    const handleViewTypeChange = (newViewType: TableViewTogglesViewType) => setViewType(newViewType);
    const handleSearchValueChange = (newSearchValue: string) => setSearchValue(newSearchValue);

    const handleColumnChange = (
        newColumnOrder: string[],
        newHiddenColumns: string[],
        newColumnsDetails = columnsDetails
    ) => {
        setColumnOrder(newColumnOrder);
        setHiddenColumns(newHiddenColumns);
        setColumnsDetails(newColumnsDetails);
    };

    // For immediate effect
    const handleColumnDetailsChange = (column: string, newColumnDetails: ColumnDetails) => {
        const updatedColumnsDetails = { ...columnsDetails };
        updatedColumnsDetails[column] = newColumnDetails;
        setColumnsDetails(updatedColumnsDetails);
    };

    const handleSortChange = (event: React.MouseEvent<HTMLElement>) => {
        const newSortBy = event.currentTarget.getAttribute('data-sortby');
        if (newSortBy) {
            handleCardSortChange(newSortBy, getSortDir(sortDir, newSortBy, sortBy));
        }
    };

    const handleCardSortChange = (newSortBy: string, newSortDir: SortDirectionType) => {
        setSortBy(newSortBy);
        setSortDir(newSortDir);
    };

    // May be extracted as a dedicated component but for demo purpose it's shown here
    const renderTableHead = (column: string, label: string, sortByColumn: string, sortDirection: SortDirectionType) => {
        const tableHeadClassNames = classNames('user-select-none', 'sort-column');

        return (
            <th
                key={column}
                className={tableHeadClassNames}
                onClick={handleSortChange}
                data-field={column}
                data-sortby={column}
                title={label}
            >
                <span>
                    {sortByColumn === column ? <SortArrows direction={sortDirection} /> : <SortArrows />}
                    <span>{label}</span>
                </span>
            </th>
        );
    };

    const renderTableCaption = (column: string, columnDetails: ColumnDetails) => {
        const style = columnDetails?.width
            ? {
                  minWidth: columnDetails.width,
                  width: columnDetails.width,
              }
            : {};

        return <col key={column} style={style} />;
    };

    // filter for hidden columns
    const columns = columnOrder.filter(name => !hiddenColumns.includes(name));

    // filter data to omit hidden columns
    const withoutHidden = omit(hiddenColumns);
    const filteredRows = vehicleList.map(vehicle => ({ ...withoutHidden(vehicle) })) as Vehicle[];

    // in case a search value is given, filter the data accordingly
    const searchResult = !searchValue
        ? filteredRows
        : filteredRows.filter((row: Partial<Vehicle>) =>
              columns.some(col => {
                  const value = row[col as keyof Vehicle];
                  if (value == null) {
                      return false;
                  }
                  row[col as keyof Vehicle]?.toString().toLowerCase().includes(searchValue.toLowerCase());
              })
          );

    // Sort rows according to the sortBy and sortDir settings
    const rows = sortBy ? naturalSortByProperty(searchResult, sortBy as keyof Vehicle, sortDir) : searchResult;

    const tableClassNames = classNames(
        'table',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-bordered',
        'table-sticky',
        'table-head-filled',
        viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD && 'table-cards table-single-card',
        viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && 'table-cards table-multi-cards'
    );

    const isViewTypeTable = viewType === TableViewToggles.VIEW_TYPE_TABLE;

    const cardSortingSelectOptions = columns.map(column => {
        return {
            id: column,
            label: columnLabels[column],
            selected: column === sortBy,
            disabled: false,
        };
    });

    return (
        <div id='TableCommonDemo'>
            <TableToolbar>
                <div className='table-toolbar-container'>
                    <div className='table-toolbar-group-left'>
                        <div className='table-toolbar-column'>
                            <div className='btn-toolbar table-btn-toolbar'>
                                <Button bsStyle={Button.PRIMARY} iconName='rioglyph-plus'>
                                    New
                                </Button>
                                <Button>Button</Button>
                                <ButtonDropdown id='myActionButton' title='Button' items={dummyActionOptions} />
                            </div>
                        </div>
                    </div>
                    <div className='table-toolbar-group-right'>
                        <div className='table-toolbar-column'>
                            <TableSearch
                                value={searchValue}
                                onChange={handleSearchValueChange}
                                placeholder='Search in table'
                            />
                        </div>
                        <div className='table-toolbar-column table-toolbar-column-spacer'>
                            <label className='table-toolbar-label'>Label</label>
                            <TableViewToggles
                                initialViewType={viewType}
                                onViewTypeChange={handleViewTypeChange}
                                tableViewTooltipContent='Table view'
                                singleCardViewTooltipContent='List view'
                                multiCardsViewTooltipContent='Cards view'
                            />
                        </div>
                        <div className='table-toolbar-column'>
                            <Button iconOnly iconName='rioglyph-settings' onClick={handleToggleTableSettingsDialog} />
                        </div>
                    </div>
                    {showTableSettingsDialog && (
                        <TableSettingsDialog
                            show={showTableSettingsDialog}
                            title='Table settings'
                            onHide={handleToggleTableSettingsDialog}
                            onColumnChange={handleColumnChange}
                            defaultColumnOrder={defaultColumnOrder}
                            defaultHiddenColumns={[]}
                            columnOrder={columnOrder}
                            hiddenColumns={hiddenColumns}
                            columnLabels={columnLabels}
                            disabledColumns={disabledColumns}
                            closeButtonText='Close'
                            resetButtonText='Reset to default'
                            searchPlaceholder='Search by column name'
                            notFoundMessage='No column found for this search value'
                            columnsDetails={columnsDetails}
                            autoLabel='Auto'
                            onColumnDetailsChange={handleColumnDetailsChange}
                            onSearchChange={(val: string) => console.log(val)}
                            immediateChange
                        />
                    )}
                </div>
            </TableToolbar>
            {sortBy && !isViewTypeTable && (
                <TableCardsSorting
                    selectOptions={cardSortingSelectOptions}
                    sortName={sortBy}
                    sortOrder={sortDir}
                    onSortChange={handleCardSortChange}
                />
            )}
            <div>
                {isEmpty(rows) && !isViewTypeTable && (
                    <NotFoundState headline='Nothing found' message='Please refine your search' />
                )}
                <table className={tableClassNames}>
                    <colgroup>
                        {columns.map(column => renderTableCaption(column, columnsDetails[column]))}
                        <col className='table-action' />
                    </colgroup>
                    <thead>
                        <tr>
                            {columns.map(column => renderTableHead(column, columnLabels[column], sortBy, sortDir))}
                            <th className='table-action' />
                        </tr>
                    </thead>
                    <tbody>
                        {isEmpty(rows) && isViewTypeTable && (
                            <tr>
                                <td colSpan={columns.length + 1}>
                                    <NotFoundState
                                        outerClassName='border-none'
                                        headline='Nothing found'
                                        message='Please refine your search'
                                    />
                                </td>
                            </tr>
                        )}
                        {rows.map((row: Vehicle, index: number) => (
                            <tr key={index}>
                                {columns.map(col => (
                                    <td key={col} data-field={columnLabels[col]}>
                                        <span>{row[col as keyof Vehicle]}</span>
                                    </td>
                                ))}
                                <td className='table-action'>
                                    <DummyRowDropdown />
                                </td>
                            </tr>
                        ))}
                        {/* Placeholder workaround for equal with cards of the last row */}
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                        <tr className='table-card-placeholder' />
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const DummyRowDropdown = () => (
    <span>
        <ButtonDropdown
            title={<span className='rioglyph rioglyph-option-vertical' />}
            variant='link'
            iconOnly
            items={[
                {
                    value: (
                        <div className='display-flex align-items-center gap-10'>
                            <span className='rioglyph rioglyph-pencil' />
                            <span>Edit</span>
                        </div>
                    ),
                },
                {
                    value: (
                        <div className='display-flex align-items-center gap-10'>
                            <span className='rioglyph rioglyph-duplicate' />
                            <span>Duplicate</span>
                        </div>
                    ),
                },
                {
                    value: (
                        <div className='display-flex align-items-center gap-10'>
                            <span className='rioglyph rioglyph-trash' />
                            <span>Delete</span>
                        </div>
                    ),
                },
            ]}
        />
    </span>
);

export default TableCommonDemo;
