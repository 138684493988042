import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';

import { icons, getRandomIcon } from '../../../utils/icons';
import { getNames } from '../../../utils/mapUtils';
import { colors, getColorNames } from '../../../utils/colors';

const vehicleIcons = [...getNames(icons.vehicles)] as string[];

const eachColor = getColorNames(colors.brand, colors.status) as string[];

export const RioglyphFilled = () => (
    <div className='uikit-icons'>
        <Description headline='Filled icons' headlineType='h3' anchor='filledIcons' />
        <Playground example={rioglyphFilledIconsExample} />
    </div>
);

const rioglyphFilledIconsExample = (
    <>
        <div className='display-flex flex-wrap gap-50'>
            <div>
                <Title>Default filled icons</Title>
                <div className='display-flex flex-wrap gap-10'>
                    {vehicleIcons.map(icon => (
                        <span className={`rioglyph ${icon} rioglyph-filled text-size-h1`} key={icon} />
                    ))}
                </div>
            </div>
            <div>
                <Title>Colored filled icons</Title>
                <div className='display-flex flex-wrap gap-10'>
                    {eachColor.map(color => (
                        <span
                            className={`rioglyph ${getRandomIcon()} rioglyph-filled text-color-${color} text-size-h1`}
                            key={color}
                        />
                    ))}
                </div>
            </div>
        </div>
        <div className='margin-top-50'>
            <div className='margin-top--5'>
                <div className='text-medium margin-bottom-5'>
                    Good use cases examples for icons with a filled circle background
                </div>
            </div>
            <ul className='list-style-none padding-0 margin-0 space-y-5'>
                <li className='display-flex gap-5 margin-left-0'>
                    <span className='rioglyph rioglyph-ok rioglyph-filled text-color-success margin-top-3' />
                    <div>
                        <span className='text-medium'>Headline enhancements:</span> For emphasizing key points in
                        headings or subheadings, like "New features" or "Highlights," where the icon in a filled circle
                        draws attention without being too overwhelming.
                    </div>
                </li>
                <li className='display-flex gap-5 margin-left-0'>
                    <span className='rioglyph rioglyph-ok rioglyph-filled text-color-success margin-top-3' />
                    <div>
                        <span className='text-medium'>Help/Documentation pages:</span> Icons with filled circles are
                        perfect for highlighting actions or key steps in a guide. They help break down text-heavy
                        content and make instructions visually appealing. Example: For "step-by-step" instructions, an
                        icon inside a circle could represent each action (e.g., a checkmark icon to signify completed
                        tasks or a question mark for additional help).
                    </div>
                </li>
                <li className='display-flex gap-5 margin-left-0'>
                    <span className='rioglyph rioglyph-ok rioglyph-filled text-color-success margin-top-3' />
                    <div>
                        <span className='text-medium'>Feature lists and cards:</span> Use filled circle icons as design
                        elements for feature lists, product highlights, or feature comparisons. This works especially
                        well when listing distinct features of a product, app, or service.
                    </div>
                </li>
            </ul>
            <div className='margin-top-15'>
                <div className='text-medium margin-bottom-5'>When NOT to use a filled circle icon</div>
                <ul className='list-style-none padding-0 margin-0 space-y-5'>
                    <li className='display-flex gap-5 margin-left-0'>
                        <span className='rioglyph rioglyph-remove rioglyph-filled text-color-danger margin-top-3' />
                        <div>
                            <b>Avoid inconsistent usage</b> in buttons – If some buttons have a filled circle and others
                            don’t, the UI becomes inconsistent.
                        </div>
                    </li>
                    <li className='display-flex gap-5 margin-left-0'>
                        <span className='rioglyph rioglyph-remove rioglyph-filled text-color-danger margin-top-3' />
                        <div>
                            Don’t use it for simple inline icons – Icons inside text (e.g., "🔍 Search") or standalone
                            icons in tables don’t need a background.
                        </div>
                    </li>
                    <li className='display-flex gap-5 margin-left-0'>
                        <span className='rioglyph rioglyph-remove rioglyph-filled text-color-danger margin-top-3' />
                        <div>
                            Not for decorative or unnecessary elements – If an icon doesn’t need extra emphasis, the
                            filled circle is overkill.
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </>
);
