import noop from 'lodash/fp/noop';
import { NavLink } from 'react-router-dom';

import { UikitMenuSidebar } from '../../components/UikitMenuSidebar';
import { UikitMenu } from '../../components/UikitMenu';
import { routes } from '../../routes';

const base = routes.MAP;
const title = 'Map';

export const getMapMenuItems = () => {
    const menu = [
        {
            group: 'How to',
            navItems: [
                {
                    key: 'Getting started',
                    meta: 'gettingstarted',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/gettingstarted`}>
                            Getting Started
                        </NavLink>
                    ),
                },
            ],
        },
        {
            group: 'Components',
            navItems: [
                {
                    key: 'Map',
                    meta: 'map',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/map`}>
                            Map
                        </NavLink>
                    ),
                },
                {
                    key: 'Cluster',
                    meta: 'cluster',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/cluster`}>
                            Cluster
                        </NavLink>
                    ),
                },
                {
                    key: 'Marker',
                    meta: 'marker',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/marker`}>
                            Marker
                        </NavLink>
                    ),
                },
                {
                    key: 'DraggableMarker',
                    meta: 'Draggable marker',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/draggableMarker`}>
                            Draggable marker
                        </NavLink>
                    ),
                },
                {
                    key: 'Group',
                    meta: 'group',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/group`}>
                            Group
                        </NavLink>
                    ),
                },
                {
                    key: 'Map settings',
                    meta: 'settings',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/settings`}>
                            Map Settings
                        </NavLink>
                    ),
                },
                {
                    key: 'Layer',
                    meta: 'layer',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/layer`}>
                            Layer
                        </NavLink>
                    ),
                },
                {
                    key: 'InfoBubble',
                    meta: 'infoBubble',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/infoBubble`}>
                            InfoBubble
                        </NavLink>
                    ),
                },
                {
                    key: 'Circle',
                    meta: 'circle',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/circle`}>
                            Circle
                        </NavLink>
                    ),
                },
                {
                    key: 'Polygon',
                    meta: 'polygon isoline',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/polygon`}>
                            Polygon & Isoline
                        </NavLink>
                    ),
                },
                {
                    key: 'Route',
                    meta: 'route',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/route`}>
                            Route
                        </NavLink>
                    ),
                },
                {
                    key: 'ContextMenu',
                    meta: 'context',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/context`}>
                            Context menu
                        </NavLink>
                    ),
                },
                {
                    key: 'MapUtils',
                    meta: 'utils',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/utils`}>
                            Map Utils
                        </NavLink>
                    ),
                },
            ],
        },
        {
            group: 'Demo',
            navItems: [
                {
                    key: 'MapFullscreenDemo',
                    meta: 'fullscreen',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/fullscreen`}>
                            Fullscreen map
                        </NavLink>
                    ),
                },
            ],
        },
    ];

    return menu.map(item => ({ ...item, badge: title }));
};

export const MapComponentsMenu = () => (
    <UikitMenuSidebar title={title}>
        <UikitMenu />
    </UikitMenuSidebar>
);
