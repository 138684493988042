// @ts-ignore-next-line importsNotUsedAsValues
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import noop from 'lodash/fp/noop';

import { useDropDirection } from '../../utils/useDropDirection';
import { DOWN, scrollItemIntoView, UP } from '../../utils/scrollItemIntoView';
import useEffectOnce from '../../hooks/useEffectOnce';
import useKey from '../../useKey';
import DropdownHeader from './DropdownHeader';
import NoItemMessage from './NoItemMessage';
import Spinner from '../spinner/Spinner';

const DATA_ATTRIBUTE_ID = 'data-item-id';
const DEFAULT_FOCUSED_ITEM_INDEX = 0;
const HIGHLIGHT_CLASS = 'focus';

export type OptionDOMValue = {
    id: string;
    text: string;
};

export type SelectOption = {
    /**
     * Used to identify an option.
     */
    id: string;

    /**
     * The option item text.
     */
    label: string | React.ReactNode;

    /**
     * Icon to be displayed in front of the label.
     */
    icon?: React.ReactNode;

    /**
     * Defines whether the menu item is selected.
     *
     * @default false
     */
    selected?: boolean;

    /**
     * Setting "disabled" to true will disable the respective item.
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * Will treat the given value as a menu header
     */
    header?: boolean;
};

export type BaseSelectDropdownProps<T extends SelectOption> = {
    options?: T[];
    isOpen?: boolean;
    isLoading?: boolean;
    updateDOMValues?: (values: OptionDOMValue[] | undefined) => void;
    onOpen?: (hasDropup: boolean) => void;
    onSelect?: (selectedItem: T | undefined) => void;
    onClose?: () => void;
    placeholder?: string | React.ReactNode;
    dropup?: boolean;
    pullRight?: boolean;
    autoDropDirection?: boolean;
    noItemMessage?: string | React.ReactNode;
    focusedItemIndex?: number;
    dropdownClassName?: string;
    keyboardUsed?: boolean;
    useActiveClass?: boolean;
};

const BaseSelectDropdown = <T extends SelectOption>(props: BaseSelectDropdownProps<T>) => {
    const {
        isOpen = false,
        isLoading = false,
        updateDOMValues = noop,
        onOpen = noop,
        onSelect = noop,
        onClose = noop,
        options = [],
        autoDropDirection = true,
        dropup = false,
        pullRight = false,
        useActiveClass = false,
        focusedItemIndex: externalFocusedItemIndex,
        keyboardUsed: externalKeyboardUsed,
        noItemMessage,
        dropdownClassName,
    } = props;

    const [focusedItemIndex, setFocusedItemIndex] = useState(externalFocusedItemIndex || DEFAULT_FOCUSED_ITEM_INDEX);
    const [keyboardUsed, setKeyboardUsed] = useState(externalKeyboardUsed);

    const dropdownMenuRef = useRef<HTMLUListElement>(null);

    useEffectOnce(() => {
        // all available items need to be rendered in order to know their DOM value
        // which will be used for filtering in the parent component
        const currentItemDOMValues = updateItemDOMValues();
        updateDOMValues(currentItemDOMValues);
    });

    // Overwrite position of dropdown menu in case auto drop is enabled
    const dropDirection = useDropDirection({ pullRight, dropup, autoDropDirection, dropdownMenuRef }, [isOpen]);

    // Add or remove the "dropup" class from the parent Select/Multiselect component to position
    // the dropdown element accordingly via CSS
    useLayoutEffect(() => {
        if (dropdownMenuRef.current) {
            const parent = dropdownMenuRef.current.parentElement;
            if (dropDirection.dropup) {
                parent?.classList.add('dropup');
            } else {
                parent?.classList.remove('dropup');
            }
        }
    }, [dropDirection, dropdownMenuRef]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (dropdownMenuRef.current) {
            updateDOMValues(updateItemDOMValues());
        }
    }, [dropdownMenuRef.current]);

    // update internal state for isOpen
    const [previousIsOpen, setPreviousIsOpen] = useState(isOpen);
    if (isOpen && !previousIsOpen) {
        onOpen(dropDirection.dropup);
        setPreviousIsOpen(isOpen);
    } else if (!isOpen && previousIsOpen) {
        onClose();
        setPreviousIsOpen(isOpen);
    }

    useKey(event => {
        if (isOpen) {
            switch (event.key) {
                case 'Escape': {
                    // close dropdown on esc
                    onClose();
                    break;
                }
                case 'Tab': {
                    // close dropdown on tab
                    onClose();
                    break;
                }
                case 'Enter': {
                    // select item on enter
                    selectOptionOnEnter(event);
                    break;
                }
                case 'ArrowUp': {
                    // prevent scrolling the page when dropdown menu is open
                    event.preventDefault();

                    // select item above on arrow up key
                    focusOption(UP);
                    scrollItemIntoView(UP, dropdownMenuRef.current, getFocusedOptionNode());
                    break;
                }
                case 'ArrowDown': {
                    // prevent scrolling the page when dropdown menu is open
                    event.preventDefault();

                    // select item below on arrow down key
                    focusOption(DOWN);
                    scrollItemIntoView(DOWN, dropdownMenuRef.current, getFocusedOptionNode());
                    break;
                }
                default:
                    break;
            }
        }
    });

    const focusOption = (direction: typeof UP | typeof DOWN) => {
        let nextFocusedItem = 0;

        switch (direction) {
            case UP: {
                nextFocusedItem = focusedItemIndex <= 0 ? focusedItemIndex : focusedItemIndex - 1;
                break;
            }
            case DOWN: {
                nextFocusedItem = focusedItemIndex === options.length - 1 ? focusedItemIndex : focusedItemIndex + 1;
                break;
            }
            default:
                break;
        }

        // In case the next item index is negative, means outside the bounds of the items,
        // reset it depending on the current direction
        const indexLimit = direction === UP ? options.length - 1 : 0;

        setFocusedItemIndex(nextFocusedItem < 0 ? indexLimit : nextFocusedItem);
        setKeyboardUsed(true);
    };

    const getOptionNodes = () => {
        const node = dropdownMenuRef.current;
        return node?.getElementsByTagName('a') || [];
    };

    const updateItemDOMValues = () => {
        if (dropdownMenuRef.current) {
            const optionNodes = getOptionNodes();
            return [...optionNodes].map(item => {
                return {
                    id: item.getAttribute(DATA_ATTRIBUTE_ID),
                    text: item.textContent,
                } as OptionDOMValue;
            });
        }
    };

    const getFocusedOptionNode = () => {
        const optionNodes = getOptionNodes();
        return [...optionNodes].find(item => item.className.includes(HIGHLIGHT_CLASS));
    };

    const selectOptionOnEnter = (event: KeyboardEvent) => {
        event.preventDefault();

        // When no filter result was found, avoid selecting anything
        if (isEmpty(options)) {
            return;
        }

        const match = getFocusedOptionNode();

        if (match) {
            const selectedItem = options.find(option => option.id === match.getAttribute(DATA_ATTRIBUTE_ID));
            onSelect(selectedItem);
        }
    };

    const handleOptionChange = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        const optionId = event.currentTarget.getElementsByTagName('input')[0].value;
        const selectedItem = options.find(option => option.id === optionId);

        onSelect(selectedItem);
    };

    const dropdownMenuClasses = classNames('dropdown-menu', dropDirection.pullRight && 'pull-right', dropdownClassName);

    // Don't show the dropdown, when no match are found when filtering unless there is a not found message
    if (isEmpty(options)) {
        return <NoItemMessage noItemMessage={noItemMessage} className={dropdownMenuClasses} />;
    }

    return (
        <ul className={dropdownMenuClasses} ref={dropdownMenuRef} role='menu'>
            {isLoading && (
                <div className='display-flex justify-content-center padding-10'>
                    <Spinner />
                </div>
            )}
            {!isLoading &&
                options.map((option, index) => {
                    if (option.header) {
                        return <DropdownHeader key={option.id} icon={option.icon} label={option.label} />;
                    }

                    // Show focused style only when keyboard is in use
                    const anchorClassNames = classNames(
                        keyboardUsed && focusedItemIndex === index ? HIGHLIGHT_CLASS : '',
                        option.disabled && 'pointer-events-none',
                        'display-flex align-items-center gap-3'
                    );

                    const wrapperClassNames = classNames(
                        option.disabled && 'disabled',
                        useActiveClass && option.selected && 'active'
                    );

                    return (
                        <li key={option.id} className={wrapperClassNames} role='listitem'>
                            <a
                                role='menuitem'
                                className={anchorClassNames}
                                data-item-id={option.id}
                                data-item-index={index}
                                // Note, we need to assign the click callback only when it's not disabled
                                // otherwise the functions is still triggered
                                // biome-ignore lint/a11y/useValidAnchor: due to old structure + backwards compatibility
                                onClick={option.disabled ? undefined : handleOptionChange}
                            >
                                <span className='selected-option-dropdown-item'>
                                    {option.icon && <>{option.icon}</>}
                                    {option.label}
                                </span>
                                <input type='hidden' value={option.id} />
                            </a>
                        </li>
                    );
                })}
        </ul>
    );
};

export const filterOptions = <T extends SelectOption>(
    itemDOMValues: OptionDOMValue[],
    filterValue: string,
    options: T[]
) => {
    const filteredDOMValues = itemDOMValues.filter(item => item.text.toLowerCase().includes(filterValue.toLowerCase()));

    // Filter the options according to the filtered DOM values and map the IDs since the filter cannot be done
    // on the options itself as they might contain arbitrary components
    return options.filter(option => {
        return filteredDOMValues.find(domValue => domValue.id === option.id);
    });
};

export default BaseSelectDropdown;
