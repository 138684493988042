import { getColorScheme, listenForSchemeChange } from '../colorScheme';
import { isTest, isInIframe } from './initConfig';
import { usedUikitVersion } from './usedUikitVersion';
import { weAreHiring } from './weAreHiring';
import { doNotUseTailwind } from './doNotUseTailwind';
import { checkCSSVersion, initCSS } from './initCSS';
import { checkForReleaseVersion } from './checkForReleaseVersion';
import { getConsoleStyle } from './styledLogs';

export const initDocumentBootstrapping = async () => {
    if (!document || !document.documentElement) {
        return;
    }

    // Initialize the CSS setup
    initCSS();

    if (!isTest) {
        // Handle the current color scheme (e.g., light/dark mode)
        getColorScheme();
        listenForSchemeChange();

        // Verify the UIKIT version of the application
        await checkForReleaseVersion();

        // Ensure the correct CSS version is being used
        checkCSSVersion();

        if (!isInIframe) {
            const logStyled = (message: string) => console.log('%c%s', getConsoleStyle(), message);

            logStyled('- - - - - - - - - - - - - - - - - -');
            // Log or validate the UI Kit version
            usedUikitVersion();

            // Show a hiring message
            weAreHiring();
            logStyled('- - - - - - - - - - - - - - - - - -');
        }
    }

    // Enforce the rule that Tailwind CSS should not be used
    await doNotUseTailwind();
};
