import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import UikitPackageJson from '../../../package.json';
import ActionBarItem from '../../../src/ActionBarItem';
import useWindowResize from '../../../src/useWindowResize';
import Select from '../../../src/Select';

/**
 * The versionsList.json has the following format:
 * {
 *     "id": "0.15.0",
 *     "label": "v0.15.0",
 *     "path": "0.15.0/"
 *     "url": "https://uikit.developers.rio.cloud/0.15.0/",
 *     "tag": "Beta"
 * }
 *
 * Use the tag property for things like "Beta" or alike.
 */

const getWindowWidth = () => {
    return window.innerWidth;
};

export const VersionSwitcher = React.memo(() => {
    const [versionsList, setVersionsList] = useState();
    const [windowWidth, setWindowWidth] = useState(getWindowWidth());

    const currentUikitVersion = UikitPackageJson.version;
    const shortVersion = currentUikitVersion.split('-')[0];

    useEffect(() => {
        let isSubscribed = true;

        if (versionsList) {
            return;
        }

        const fetchVersions = async () => {
            try {
                const response = await fetch('/versionsList.json');
                const data = await response.json();

                if (isSubscribed) {
                    setVersionsList(data);
                }
            } catch (error) {
                if (isSubscribed) {
                    setVersionsList({
                        latest_release: shortVersion,
                        versions: [
                            {
                                id: shortVersion,
                                label: `v${shortVersion}`,
                                path: 'latest/',
                                url: 'https://uikit.developers.rio.cloud/latest/',
                                tag: 'Beta',
                            },
                        ],
                    });
                }
            }
        };

        fetchVersions();

        // cancel any future `setData` to avoid react async errors
        return () => (isSubscribed = false);
    }, []);

    useWindowResize(() => {
        setWindowWidth(getWindowWidth());
    });

    const handleDesktopVersionSwitch = item => {
        // Keep deep linking while switching versions
        if (item.url) {
            const slash = item.url.endsWith('/') ? '' : '/';
            window.location.href = `${item.url}${slash}${window.location.hash}`;
        }
        // biome-ignore lint/style/useTemplate: <explanation>
        console.log('Switched to Version: ' + item.label);
    };

    const handleMobileVersionSwitch = (url, label) => {
        // Keep deep linking while switching versions
        const slash = url.endsWith('/') ? '' : '/';
        window.location.href = `${url}${slash}${window.location.hash}`;
        console.log(`Switched to Version: ${label}`);
    };

    const options = versionsList?.versions.map(version => ({
        id: version.id,
        label: version.label,
        url: `/${version.path}`,
        icon: version.tag && <span className='label label-condensed label-filled label-muted'>{version.tag}</span>,
        selected: currentUikitVersion.startsWith(version.id),
    }));

    const listItemClassnames = classNames(
        'display-flex padding-x-15 padding-y-10',
        'hover-bg-lightest cursor-pointer',
        'border border-bottom-only border-color-lighter last-child-border-bottom-none'
    );

    return (
        <>
            {windowWidth > 990 ? (
                <ActionBarItem
                    id='versions'
                    className='VersionSwitcher display-flex align-items-center width-auto margin-right-10'
                >
                    <Select
                        className='form-group margin-0'
                        options={options}
                        pullRight
                        onChange={handleDesktopVersionSwitch}
                    />
                </ActionBarItem>
            ) : (
                <ActionBarItem id='versions' mobileDialogBodyClassName='padding-0'>
                    <ActionBarItem.Icon>
                        <span className='icon rioglyph rioglyph-layer' />
                    </ActionBarItem.Icon>

                    <ActionBarItem.Popover title='Choose a Version' className='padding-0'>
                        {versionsList?.versions.map(version => (
                            <div
                                className={`${listItemClassnames} ${
                                    currentUikitVersion.startsWith(version.id) ? 'pointer-events-none' : ''
                                }`}
                                key={version.id}
                                onClick={() => handleMobileVersionSwitch(version.url, version.label)}
                            >
                                <div className='flex-1-1 display-flex justify-content-between'>
                                    <div>
                                        {currentUikitVersion.startsWith(version.id) && (
                                            <span className='rio-glyph rioglyph-ok margin-right-10' />
                                        )}
                                        {version.label}
                                    </div>
                                    {version.tag && (
                                        <span className='label label-condensed label-filled label-muted margin-bottom-3'>
                                            {version.tag}
                                        </span>
                                    )}
                                </div>
                            </div>
                        ))}
                    </ActionBarItem.Popover>
                </ActionBarItem>
            )}
        </>
    );
});
