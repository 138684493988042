import { Playground } from '../../../components/playground/Playground';
import { PlaygroundColors } from '../../../components/playground/PlaygroundColors';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

import { colors, getColorNames } from '../../../utils/colors';

export const Alerts = () => (
    <div>
        <Description headline='Alerts' anchor='alerts' />
        <Playground
            example={alerts}
            classes={alertsClasses}
            availableColors={<PlaygroundColors bgPrimary bgSecondary bgInfo bgSuccess bgWarning bgDanger />}
        />
    </div>
);

const eachColor = getColorNames(colors.brand, colors.status);

const getAlertIconClasses = color => {
    switch (color) {
        case 'info':
            return 'rioglyph-info-sign';
        case 'success':
            return 'rioglyph-ok-sign';
        case 'warning':
            return 'rioglyph rioglyph-exclamation-sign';
        case 'danger':
            return 'rioglyph rioglyph-error-sign';
        default:
            return 'rioglyph-info-sign';
    }
};

const alerts = (
    <div className='display-grid grid-cols-1 grid-cols-2-ls grid-cols-3-md gap-20'>
        {eachColor.map((color: string, index: number) => (
            <div className={`alert alert-dismissible alert-${color}`} key={index}>
                <button type='button' className='btn btn-icon-only close' data-dismiss='alert'>
                    <span className='rioglyph rioglyph-remove' />
                </button>
                <div className='display-flex gap-10'>
                    <span className={`text-color-${color} text-size-h4 rioglyph ${getAlertIconClasses(color)}`} />
                    <div>
                        <strong className='text-size-16'>Lorem ipsum</strong>
                        <div className='margin-y-5'>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.
                        </div>
                        <div className='text-medium text-uppercase text-size-12'>
                            <a href='#' className='alert-link'>
                                See More
                                <span className={`text-color-${color} margin-left-3 rioglyph rioglyph-new-window`} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </div>
);

const alertsClasses = <PlaygroundClasses label='Colors' prefix='alert-' classes={eachColor} />;
