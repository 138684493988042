import { Description } from '../../../components/Description';
import { Playground } from '../../../components/playground/Playground';
import FormLabel from '../../../../../src/FormLabel';
import { dummyText, generateDummyText } from '../../../utils/data';

export const RioglyphTextAlignment = () => {
    return (
        <div className='uikit-icons'>
            <Description headline='RIOglyph text alignment' headlineType='h3' anchor='customIcons' />
            <Playground example={alignmentExample} />
        </div>
    );
};

const alignmentExample = (
    <div>
        <p>
            When an icon is used alongside text in a flex, grid, or inline text layout, additional alignment adjustments
            may be needed. To ensure proper alignment, the following classes are available:
        </p>
        <ul className='space-y-5'>
            <li>
                <code>rioglyph-align-top</code> - assuming a display-flex and default line-height
            </li>
            <li>
                <code>rioglyph-align-bottom</code> - assuming a display-flex and line-height 1.25
            </li>
            <li>
                <code>rioglyph-align-baseline</code> - assuming an inline text and default line-height
            </li>
        </ul>
        <div>These classes help align icons correctly within different layouts where they are used alongside text.</div>
        <div className='margin-top-10 padding-bottom-10'>
            <b>Note</b>: Setting a text line-height class will also affect the icon alignment and may require manual
            adjustments to ensure proper positioning.
        </div>
        <div className='margin-top-25 display-grid grid-cols-1 grid-cols-2-sm gap-25'>
            <div>
                <FormLabel>
                    <b>Baseline</b>-aligned icon with <b>inline text</b>
                </FormLabel>
                <div className='bg-lightest padding-15 rounded'>
                    <span className='rioglyph rioglyph-face-smile rioglyph-align-baseline' /> Lorem ipsum dolor
                </div>
            </div>

            <div>
                <FormLabel>
                    <b>Top</b>-aligned icon with text inside a <b>flex</b>-box layout
                </FormLabel>
                <div className='bg-lightest padding-15 rounded display-flex align-items-start gap-5'>
                    <span className='rioglyph rioglyph-face-smile rioglyph-align-top' />
                    <span>{generateDummyText(dummyText, 8, 12)}</span>
                </div>
            </div>

            <div>
                <FormLabel>
                    <b>Bottom</b>-aligned icon inside an <b>inline-flex</b> layout and <b>line-height-125rel</b>
                </FormLabel>
                <div className='bg-lightest padding-15 rounded display-inline-flex align-items-start gap-5 line-height-125rel width-100pct'>
                    <span className='rioglyph rioglyph-face-smile rioglyph-align-bottom' />
                    <span>{generateDummyText(dummyText, 8, 12)}</span>
                </div>
            </div>

            <div>
                <FormLabel>Icons inside a link usually don't need to be aligned manually</FormLabel>
                <div className='bg-lightest padding-15 rounded width-auto'>
                    <a href='#'>
                        <span className='rioglyph rioglyph-truck' />
                        <span>Lorem ipsum</span>
                    </a>
                </div>
            </div>

            <div>
                <FormLabel>
                    <b>Baseline</b>-aligned icon inside an inline h3 header text
                </FormLabel>
                <div className='bg-lightest padding-15 rounded width-auto text-color-darker'>
                    <h3 className='margin-0'>
                        <span className='rioglyph rioglyph-truck rioglyph-align-baseline' />
                        <span> {generateDummyText(dummyText, 3, 5, true)}</span>
                    </h3>
                    <div className='text-size-h3 text-bold margin-top-10'>
                        <span className='rioglyph rioglyph-truck rioglyph-align-baseline' />
                        <span> {generateDummyText(dummyText, 3, 5, true)}</span>
                    </div>
                </div>
            </div>

            <div>
                <FormLabel>
                    <b>Baseline</b>-aligned icon inside an inline h4 header text
                </FormLabel>
                <div className='bg-lightest padding-15 rounded width-auto text-color-darker'>
                    <h4 className='margin-0'>
                        <span className='rioglyph rioglyph-truck rioglyph-align-baseline' />
                        <span> {generateDummyText(dummyText, 3, 5, true)}</span>
                    </h4>
                    <div className='text-size-h4 text-bold margin-top-10'>
                        <span className='rioglyph rioglyph-truck rioglyph-align-baseline' />
                        <span> {generateDummyText(dummyText, 3, 5, true)}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
