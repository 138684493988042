import { getConsoleStyle } from './styledLogs';

export const doNotUseTailwind = async () => {
    // Detect Tailwind CSS in linked stylesheets
    const links = document.querySelectorAll('link[rel="stylesheet"]') as NodeListOf<HTMLLinkElement>;
    for (const link of links) {
        try {
            const response = await fetch(link.href);
            const cssText = await response.text();

            if (cssText.includes('--tw')) {
                return true; // Tailwind detected
            }
        } catch (error) {
            console.warn(`Failed to fetch CSS file: ${link.href}`, error);
        }
    }

    // Detect Tailwind CSS in <style> tags
    const styleTags = document.querySelectorAll('style') as NodeListOf<HTMLStyleElement>;
    for (const style of styleTags) {
        if (style.textContent?.includes('--tw')) {
            return true; // Tailwind detected
        }
    }

    return false; // Tailwind not detected
};

// Global flag to track if Tailwind has been detected
let tailwindDetected = false;

// Initial detection of Tailwind CSS
(async () => {
    try {
        tailwindDetected = await doNotUseTailwind();
    } catch (error) {
        console.error('Error during Tailwind detection:', error);
    }
})();

// Repeatedly log messages every 10 seconds if Tailwind was detected
setInterval(() => {
    if (tailwindDetected) {
        const message = [
            'TAILWIND DETECTED!!!',
            'Using Tailwind CSS in combination with the RIO UIKIT is strongly discouraged because it can lead to significant design and development issues.',
            'The RIO UIKIT is a component-driven design system with carefully crafted styles to ensure consistent branding and user experience.',
            'Tailwind CSS, as a utility-first framework, introduces inline styling that can conflict with the RIO UIKIT’s predefined component styles.',
            'This can result in overridden styles, broken layouts, inconsistent spacings, inconsistent design language, and increased maintenance complexity.',
            'Tailwind’s global reset styles may also disrupt the base styles of the RIO UIKIT, causing unintended visual changes.',
            'Furthermore, mixing the two systems creates unnecessary redundancy, increases CSS bundle size, and complicates debugging and future updates.',
            'To ensure a cohesive, maintainable, and professional design system, it is essential to rely solely on the RIO UIKIT and its customization options rather than introducing conflicting styling frameworks.',
            'WE MAY TECHNICALLY DISABLE TAILWIND FUNCTIONALITY IN THE FUTURE.\nPLEASE DO NOT USE TAILWIND!',
        ].join('\n\n');
        console.warn('%c%s', getConsoleStyle(), message);
    }
}, 10_000);
