import { Link } from 'react-router-dom';

import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { PlaygroundColors } from '../../../components/playground/PlaygroundColors';

import { Description } from '../../../components/Description';
import { Callout } from '../../../components/Callout';
import { Note } from '../../../components/Note';
import { colors, getColorNames } from '../../../utils/colors';

import ButtonsExample from './ButtonsExample';
import ButtonsStatesExample from './ButtonsStatesExample';
import ButtonsSizesExample from './ButtonsSizesExample';
import ButtonsExtendedExample from './ButtonsExtendedExample';
import ButtonsActionsExample from './ButtonsActionsExample';

const eachColor = getColorNames(colors.brand, colors.status);

export const Buttons = () => (
    <>
        <Description headline='Buttons' anchor='buttons' />
        <Playground
            example={<ButtonsExample />}
            availableColors={<PlaygroundColors bgPrimary bgSecondary bgInfo bgSuccess bgWarning bgDanger bgMuted />}
            classes={ButtonClasses}
        />
        <Callout>
            See also our <Link to='/components/button'>Button</Link> component.
        </Callout>
        <Note>
            For having spacing between buttons in a single row you may want to wrap them with
            <code className='code-inline'>{'<div class="btn-toolbar"></div>'}</code>
        </Note>
        <Note>
            You can use{' '}
            <button type='button' className='btn btn-link btn-link-inline'>
                link buttons
            </button>{' '}
            within text blocks by adding <code className='code margin-left-5 margin-right-5'>btn-link</code>
            <code className='code'>btn-link-inline</code>
        </Note>
        <Description headline='Button states' headlineType='h3' />
        <Playground example={<ButtonsStatesExample />} />
        <Description headline='Button sizes' headlineType='h3' />
        <Playground example={<ButtonsSizesExample />} classes={ButtonSizesClasses} />
        <Description headline='Button examples' headlineType='h3' />
        <Playground example={<ButtonsExtendedExample />} classes={ButtonsExtendedClasses} />
        <Description headline='Action buttons' headlineType='h3' />
        <Playground example={<ButtonsActionsExample />} classes={ButtonsActionClasses} />
    </>
);

const ButtonClasses = (
    <>
        <PlaygroundClasses label='Default' classes='btn btn-default' />
        <PlaygroundClasses label='Colored' prefix='btn btn-' classes={eachColor} />
        <PlaygroundClasses label='Outline' prefix='btn btn-outline btn-' classes={eachColor} />
        <PlaygroundClasses label='Link' prefix='btn btn-link btn-' classes={eachColor} />
        <PlaygroundClasses label='Icon' prefix='btn btn-icon-only btn-' classes={eachColor} />
        <PlaygroundClasses label='Muted' prefix='btn btn-' classes={['muted', 'muted-filled']} />
    </>
);

const ButtonSizesClasses = (
    <>
        <PlaygroundClasses label='XS' prefix='btn btn-xs btn-' classes={eachColor} />
        <PlaygroundClasses label='SM' prefix='btn btn-sm btn-' classes={eachColor} />
        <PlaygroundClasses label='MD (default)' prefix='btn btn-' classes={eachColor} />
        <PlaygroundClasses label='LG' prefix='btn btn-lg btn-' classes={eachColor} />
    </>
);

const ButtonsExtendedClasses = (
    <>
        <PlaygroundClasses label='Wrapper' classes={['btn-toolbar', 'btn-group', 'btn-group-vertical']} />
        <PlaygroundClasses label='Variants' prefix='btn btn-' classes={['multiline', 'block']} />
    </>
);

const ButtonsActionClasses = (
    <>
        <PlaygroundClasses label='Sizes' classes={['btn-xs', 'btn-sm', 'btn-lg']} />
        <PlaygroundClasses label='Variants' prefix='btn btn-action btn-' classes={eachColor} />
    </>
);
