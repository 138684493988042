import { version } from '../../version.json';
import { getConsoleStyle } from './styledLogs';

const logStyled = (message: string) => console.log('%c%s', getConsoleStyle(), message);

export const usedUikitVersion = () => {
    const message = `RIO UIKIT Version ${version}\nhttps://uikit.developers.rio.cloud`;

    logStyled(message);
};
