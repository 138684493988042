// biome-ignore lint/style/useImportType: <explanation>
import React, { useState, useEffect, forwardRef, type PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import isNil from 'lodash/fp/isNil';
import noop from 'lodash/fp/noop';

import { getOrCreatePortalRoot } from '../../utils/portalRoot';
import useClickOutside, { DEFAULT_EVENT_TYPES } from '../../hooks/useClickOutside';
import MenuItems from '../menuItems/MenuItems';
import MenuItemList from '../menuItems/MenuItemList';
import DropdownToggleButton from './DropdownToggleButton';
import SplitCaretButton from './SplitCaretButton';
import Caret from './Caret';
import type { MenuItemProps as MenuItem } from '../menuItems/MenuItem';
import type { BUTTON_SIZE, BUTTON_STYLE, BUTTON_VARIANT } from '../button/Button';

const getPlacement = (pullRight: boolean, dropup: boolean) => {
    if (pullRight && dropup) {
        return 'top-end';
    }
    if (!pullRight && dropup) {
        return 'top-start';
    }
    if (pullRight && !dropup) {
        return 'bottom-end';
    }
    return 'bottom-start';
};

export type ButtonDropdownProps = {
    /**
     * Unique button id. If not present a random id is generated.
     */
    id?: string;

    /**
     * The button title. This may be also a node, like a <span> or a <FormattedMessage>.
     */
    title: string | React.ReactNode;

    /**
     * Defined weather or not the menu is rendered.
     * Use this to control the component from the outside.
     *
     * @default undefined
     */
    open?: boolean;

    /**
     * Defines whether the dropdown opens above or below.
     * Set it to "true" additionally disables the automatic position feature.
     *
     * @default false
     */
    dropup?: boolean;

    /**
     * Defines whether the dropdown opens right aligned to the dropdown or not.
     */
    pullRight?: boolean;

    /**
     * Defines how large the button will be rendered.
     *
     * Possible values are: `xs`, `sm`, `md`, `lg`
     */
    bsSize?: BUTTON_SIZE;

    /**
     * Defines the button color.
     *
     * Possible values are: `default`, `primary`, `secondary`, `info`, `warning`, `danger`, `success`, `muted`
     */
    bsStyle?: BUTTON_STYLE;

    /**
     * Defines the variation of the button design.
     *
     * Possible values are: `link`, `link-inline`, `outline`, `action`
     */
    variant?: BUTTON_VARIANT;

    /**
     * Optional prop to defines whether the dropdown title is icon only which
     * applies different padding so the button is a square.
     *
     * @default false
     */
    iconOnly?: boolean;

    /**
     * Defines whether the caret is shown or not.
     *
     * @default false
     */
    noCaret?: boolean;

    /**
     * Defines whether the dropdown-toggle (with caret) should be in a separate button.
     *
     * @default false
     */
    splitButton?: boolean;

    /**
     * Renders the dropdown into a dedicated react portal
     *
     * @default false
     */
    usePortal?: boolean;

    /**
     * Items to display in the dropdown menu. If you use a custom dropdown you can skip this prop.
     */
    items?: MenuItem[];

    /**
     * Disables the dropdown button.
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * Callback for splitButton label button click.
     */
    onLabelButtonClick?: () => void;

    /**
     * Callback for when the toggle button was clicked to open it.
     * @param event
     * @returns
     */
    onOpen?: (event?: React.MouseEvent<HTMLButtonElement>) => void;

    /**
     * Callback for when the toggle button was clicked to close it.
     * @returns
     */
    onClose?: () => void;

    /**
     * Allows to pass in custom dropdown menu content.
     */
    customDropdown?: React.ReactNode;

    /**
     * Define custom popper.js configuration for dropdown placement and modifiers.
     */
    popperConfig?: object;

    /**
     * Additional classes to be set on the dropdown-toggle button.
     */
    toggleClassName?: string;

    /**
     * Additional classes to be set on the dropdown.
     */
    dropdownClassName?: string;

    /**
     * Additional classes to be set to the wrapper element.
     */
    className?: string;
};

const ButtonDropdown = forwardRef(
    (props: PropsWithChildren<ButtonDropdownProps>, ref: React.Ref<HTMLButtonElement>) => {
        const {
            id = Math.random().toString(36).slice(2, 16),
            items = [],
            bsSize = 'md',
            bsStyle = 'default',
            variant,
            disabled = false,
            iconOnly = false,
            title,
            splitButton = false,
            customDropdown,
            open,
            dropup = false,
            pullRight = false,
            noCaret = false,
            onOpen = noop,
            onClose = noop,
            onLabelButtonClick = noop,
            usePortal = false,
            popperConfig,
            toggleClassName = '',
            dropdownClassName,
            className = '',
            ...remainingProps
        } = props;

        const [internalOpen, setInternalOpen] = useState(open);

        const [refDropdownToggle, setRefDropdownToggle] = useState<HTMLButtonElement | null>(null);
        const [refDropdownMenu, setRefDropdownMenu] = useState<HTMLUListElement | null>(null);
        const [refSplitButtonToggle, setRefSplitButtonToggle] = useState<HTMLButtonElement | null>(null);

        const defaultPopperConfig = {
            placement: getPlacement(pullRight, dropup),
            modifiers: [],
        };

        const popperParentRef = splitButton && pullRight ? refSplitButtonToggle : refDropdownToggle;

        const { styles, attributes } = usePopper(popperParentRef, refDropdownMenu, popperConfig || defaultPopperConfig);

        const isUncontrolled = isNil(open);
        const isOpen = isUncontrolled ? internalOpen : open;

        const wrapperRef = useClickOutside(
            event => {
                if (usePortal) {
                    // In case the dropdown is rendered via portal the clickOutside the toggle button element is
                    // triggered since the dropdown is not a child of the wrapper element.
                    // In this case we need to check if the event target is inside the dropdown-menu and prevent closing
                    // the dropdown
                    if (!refDropdownMenu?.contains(event.target as Node)) {
                        closeMenu();
                    }
                } else {
                    closeMenu();
                }
            },
            DEFAULT_EVENT_TYPES,
            Boolean(isOpen) // only listen to clicks outside when the dropdown is open
        );

        const dropdownRoot = getOrCreatePortalRoot();

        const shouldShowCaret = !noCaret && !splitButton && !iconOnly;

        useEffect(() => {
            if (!isUncontrolled) {
                setInternalOpen(open);
            }
        }, [isUncontrolled, open]);

        const toggleOpen = (event?: React.MouseEvent<HTMLButtonElement>) => {
            const isDropdownOpen = isUncontrolled ? internalOpen : open;

            if (isDropdownOpen) {
                closeMenu();
            } else {
                openMenu(event);
            }
        };

        const openMenu = (event?: React.MouseEvent<HTMLButtonElement>) => {
            if (isUncontrolled) {
                setInternalOpen(true);
            }
            onOpen(event);
        };

        const closeMenu = () => {
            if (isUncontrolled) {
                setInternalOpen(false);
            }
            onClose();
        };

        const handleSplitLabelButtonClick = () => {
            closeMenu();
            onLabelButtonClick();
        };

        const handleDropdownButtonClick = splitButton ? handleSplitLabelButtonClick : toggleOpen;

        const wrapperClasses = classNames('dropdown', 'btn-group', isOpen && 'open', className);

        const dropdownClasses = classNames(
            usePortal && 'dropdown-portal',
            splitButton && pullRight && 'pull-right',
            dropdownClassName
        );

        const dropdownMenu = (
            <MenuItemList
                className={dropdownClasses}
                ref={setRefDropdownMenu}
                style={styles.popper}
                {...attributes.popper}
            >
                {customDropdown ? customDropdown : <MenuItems items={items} closeMenu={toggleOpen} />}
            </MenuItemList>
        );

        return (
            <div {...remainingProps} className={wrapperClasses} ref={wrapperRef}>
                <DropdownToggleButton
                    id={id}
                    splitButton={splitButton}
                    bsStyle={bsStyle}
                    bsSize={bsSize}
                    variant={variant}
                    iconOnly={iconOnly}
                    disabled={disabled}
                    ref={setRefDropdownToggle}
                    onClick={handleDropdownButtonClick}
                    outerRef={ref}
                    className={toggleClassName}
                >
                    <>
                        {title}
                        {shouldShowCaret && <Caret />}
                    </>
                </DropdownToggleButton>
                {splitButton && (
                    <SplitCaretButton
                        id={id}
                        bsStyle={bsStyle}
                        bsSize={bsSize}
                        disabled={disabled}
                        className={toggleClassName}
                        onClick={toggleOpen}
                        ref={setRefSplitButtonToggle}
                    />
                )}
                {isOpen && usePortal && ReactDOM.createPortal(dropdownMenu, dropdownRoot)}
                {isOpen && !usePortal && dropdownMenu}
            </div>
        );
    }
);

export default ButtonDropdown;
