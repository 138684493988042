// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren, type ComponentProps, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

export type FormLabelProps = ComponentProps<'label'> & {
    /**
     * Additional supporting text displayed next to the label.
     */
    supportingText?: string;

    /**
     * Additional classes set to the outer element.
     */
    className?: string;
};

const FormLabel = (props: PropsWithChildren<FormLabelProps>) => {
    const { className = '', children, supportingText, ...remainingProps } = props;

    const elementRef = useRef<HTMLDivElement | HTMLLabelElement>(null);

    const [parentSize, setParentSize] = useState<'lg' | 'sm' | null>(null);

    const isLabel = 'htmlFor' in remainingProps;

    useEffect(() => {
        if (!elementRef.current) {
            return;
        }

        const parent = elementRef.current.parentElement;
        if (!parent) {
            return;
        }

        if (parent.classList.contains('form-group-lg')) {
            setParentSize('lg');
        } else if (parent.classList.contains('form-group-sm')) {
            setParentSize('sm');
        }
    }, []);

    const supportingTextClasses = classNames(
        'text-color-gray',
        'text-italic',
        parentSize === 'sm' && 'text-size-10',
        parentSize === 'lg' && 'text-size-14',
        !parentSize && 'text-size-12'
    );

    if (isLabel) {
        const labelClasses = classNames(
            !supportingText && 'display-inline-block',
            supportingText && 'display-inline-flex flex-wrap justify-content-between width-100pct',
            className
        );

        return (
            // biome-ignore lint/a11y/noLabelWithoutControl: <explanation>
            <label ref={elementRef as React.RefObject<HTMLLabelElement>} {...remainingProps} className={labelClasses}>
                <span>{children}</span>
                {supportingText && <span className={supportingTextClasses}>{supportingText}</span>}
            </label>
        );
    }

    const classes = classNames(
        'text-color-dark',
        'max-width-100pct',
        parentSize === 'sm' && 'text-size-10',
        parentSize === 'lg' && 'text-size-14',
        !parentSize && 'text-size-12',
        !supportingText && 'display-inline-block',
        supportingText && 'display-inline-flex flex-wrap justify-content-between width-100pct',
        !className.includes('margin-bottom') && 'margin-bottom-5',
        className
    );

    return (
        // @ts-ignore
        <div ref={elementRef} {...remainingProps} className={classes}>
            {children}
            {supportingText && <span className={supportingTextClasses}>{supportingText}</span>}
        </div>
    );
};

export default FormLabel;
