import { Playground } from '../../../components/playground/Playground';
import { PlaygroundColors } from '../../../components/playground/PlaygroundColors';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { colors, getColorNames } from '../../../utils/colors';
import { replaceUikitImport } from '../../../utils/replaceUikitImport';
import Divider from '../../../../../src/Divider';

import LoadingProgressBarExample from './LoadingProgressBarExample';
import LoadingProgressBarExampleRaw from './LoadingProgressBarExample?raw';

export const ProgressBars = () => (
    <div>
        <Description headline='Progress bars' anchor='progress' />
        <Playground
            example={progressbarExamples}
            classes={progressbarClasses}
            availableColors={<PlaygroundColors bgPrimary bgSecondary bgInfo bgSuccess bgWarning bgDanger />}
        />
        <Playground
            example={<LoadingProgressBarExample />}
            codeReact={replaceUikitImport(LoadingProgressBarExampleRaw)}
            classes={progressbarClasses}
        />
    </div>
);

const eachColor = getColorNames(colors.brand, colors.status) as string[];
const getRandomWidth = () => 5 + Math.random() * (95 - 5);

const progressbarExamples = (
    <>
        <div className='display-grid grid-cols-1 grid-cols-2-ls grid-cols-3-md gap-20'>
            <div>
                <h5>Standard</h5>
                {eachColor.map(color => (
                    <div className='progress margin-bottom-20' key={crypto.randomUUID()}>
                        <div
                            className={`progress-bar progress-bar-${color}`}
                            style={{ width: `${getRandomWidth()}%` }}
                        />
                    </div>
                ))}
            </div>

            <div>
                <h5>Striped</h5>
                {eachColor.map(color => (
                    <div className='progress margin-bottom-20' key={crypto.randomUUID()}>
                        <div
                            className={`progress-bar progress-bar-striped progress-bar-${color}`}
                            style={{ width: `${getRandomWidth()}%` }}
                        />
                    </div>
                ))}
            </div>

            <div>
                <h5>Animated</h5>
                {eachColor.map(color => (
                    <div className='progress margin-bottom-20' key={crypto.randomUUID()}>
                        <div
                            className={`progress-bar progress-bar-striped progress-bar-animated progress-bar-${color}`}
                            style={{ width: `${getRandomWidth()}%` }}
                        />
                    </div>
                ))}
            </div>
        </div>

        <h5>Stacked</h5>
        <div className='bs-component'>
            <div className='progress'>
                <div className='progress-bar progress-bar-success progress-divider' style={{ width: '35%' }} />
                <div className='progress-bar progress-bar-warning progress-divider' style={{ width: '20%' }} />
                <div className='progress-bar progress-bar-danger progress-divider' style={{ width: '10%' }} />
            </div>
        </div>

        <h5 className='margin-top-25'>Stacked ratings</h5>
        <div className='bs-component'>
            <div className='progress'>
                <div className='progress-bar bg-rating-1 progress-divider' style={{ width: '15%' }} />
                <div className='progress-bar bg-rating-2 progress-divider' style={{ width: '20%' }} />
                <div className='progress-bar bg-rating-3 progress-divider' style={{ width: '25%' }} />
                <div className='progress-bar bg-rating-4 progress-divider' style={{ width: '15%' }} />
                <div className='progress-bar bg-rating-5 progress-divider' style={{ width: '25%' }} />
            </div>
        </div>

        <Divider className='margin-top-25' />

        <h5>Stacked ratings with custom icon overlay</h5>
        <div>
            <div className='display-flex justify-content-between'>
                <label>Poor</label>
                <label>Excelent</label>
            </div>
            <div className='position-relative'>
                <div className='progress position-relative'>
                    <div className='progress-bar bg-rating-1 progress-divider' style={{ width: '15%' }} />
                    <div className='progress-bar bg-rating-2 progress-divider' style={{ width: '20%' }} />
                    <div className='progress-bar bg-rating-3 progress-divider' style={{ width: '25%' }} />
                    <div className='progress-bar bg-rating-4 progress-divider' style={{ width: '15%' }} />
                    <div className='progress-bar bg-rating-5 progress-divider' style={{ width: '25%' }} />
                </div>
                <span
                    className='
                        bg-success
                        rounded-circle
                        border border-color-white border-width-2
                        shadow-accent
                        width-25
                        height-25
                        position-absolute
                        top-50pct
                        translate-x-50pct-y-50pct
                        display-grid place-items-center'
                    style={{ right: '30%' }}
                >
                    <span className='rioglyph rioglyph-truck' />
                </span>
            </div>
        </div>
    </>
);

const progressbarClasses = (
    <>
        <PlaygroundClasses label='Wrapper' classes='progress' />
        <PlaygroundClasses label='Base' classes='progress-bar' />
        <PlaygroundClasses label='Variants' prefix='progress-' classes={['striped', 'divider']} />
        <PlaygroundClasses label='Colors' prefix='progress-bar-' classes={eachColor} />
    </>
);
