// @ts-ignore-next-line importsNotUsedAsValues
import React, { useState, useRef, type PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import type { AnimationDefinition } from 'framer-motion/types/render/utils/animation';

import { getOrCreatePortalRoot } from '../../utils/portalRoot';
import useEsc from '../../hooks/useEsc';
import useAfterMount from '../../hooks/useAfterMount';
import DialogHeader from './DialogHeader';
import DialogBody from './DialogBody';
import DialogFooter from './DialogFooter';
import useFocusTrap from '../../hooks/useFocusTrap';
import { DialogContextProvider } from './dialogContext';

const MODAL_DIALOG_CLASS = 'modal-dialog';
const MODAL_OPEN_CLASS = 'modal-open';

export type DialogSize =
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | 'full'
    | 'fullwidth'
    | 'fullheight'
    | 'fullheight-lg'
    | 'fullheight-xl'
    | 'fullscreen';

export type BaseDialogProps = {
    /**
     * Opens the dialog when set to `true`.
     *
     * @default false
     */
    show: boolean;

    /**
     * The dialog title (can also be a FormattedMessage component).
     */
    title?: React.ReactNode;

    /**
     * The dialog header subtitle content.
     */
    subtitle?: React.ReactNode;

    /**
     * Shows a close button when set to `true`.
     *
     * @default true
     */
    showCloseButton?: boolean;

    /**
     * A callback function invoked when the dialog closes.
     */
    onClose?: () => void;

    /**
     * Defined how large the dialog will be rendered.
     *
     * By default, the dialog has a medium size, and thus it can be omitted.
     *
     * Possible values are: `xs`, `sm`, `lg` `xl` `fullwidth` `fullheight` 'fullheight-lg' 'fullheight-xl' `fullscreen`
     *
     * @default 'md'
     */
    bsSize?: DialogSize;

    /**
     * Enables or disabled closing the dialog via esc key.
     *
     * @default false
     */
    disableEsc?: boolean;

    /**
     * Enables the modal body to overflow and use inline scrolling if needed.
     *
     * @default false
     */
    useOverflow?: boolean;

    /**
     * Show Header and Footer Borders for the "xs" dialog
     *
     * @default false
     */
    showXsDialogBorders?: boolean;

    /**
     * Additional classes for the modal element.
     */
    className?: string;
};

export type DialogProps = BaseDialogProps & {
    /**
     * The dialog body content.
     */
    body?: React.ReactNode;

    /**
     * The dialog body content.
     */
    footer?: React.ReactNode;

    /**
     * Additional classes for the modal-body element.
     */
    bodyClassName?: string;

    /**
     * Additional classes for the modal-footer element.
     */
    footerClassName?: string;

    /**
     * Allows to add additional buttons to the dialog header.
     */
    headerButtons?: React.ReactNode;

    /**
     * Deprecate to align with other dialog callbacks.
     *
     * @deprecated please use `onClose`
     */
    onHide?: VoidFunction;

    /**
     * A callback fired when esc key is pressed and the dialog is about to close.
     */
    onEsc?: VoidFunction;

    /**
     * A callback function to be executed before closing the dialog.
     *
     * If the function returns `false`, the dialog will not be closed
     */
    onCloseValidation?: () => boolean;
};

const Dialog = (props: PropsWithChildren<DialogProps>) => {
    const {
        title,
        subtitle,
        body,
        footer,
        headerButtons,
        className = '',
        bodyClassName,
        footerClassName,
        showXsDialogBorders = false,
        showCloseButton = true,
        useOverflow = false,
        bsSize,
        show = false,
        onHide = noop,
        onClose,
        disableEsc = false,
        onEsc = noop,
        onCloseValidation = () => true,
        children,
        ...remainingProps
    } = props;

    const [open, setOpen] = useState(show);
    const dialogWrapperRef = useRef<HTMLDivElement>(null);

    const onCloseCallback = onClose ?? onHide;

    const modalRoot = getOrCreatePortalRoot();

    const shouldReduceMotion = useReducedMotion();

    // Use a setter function for the focus trap as it would not re-render
    // and fails to set the focus listener
    const [focusTrapRef, setFocusTrapRef] = useState<HTMLDivElement>();
    useFocusTrap(focusTrapRef);

    const toggleBodyClass = (add: boolean) => {
        // We need to set a body class to fix the -webkit-overflow-scrolling on safari and iOS

        // Remove "modal-open" from body only when there is no other dialog in the DOM
        // in order to support multiple dialogs
        const hasOtherDialogs = modalRoot.getElementsByClassName(MODAL_DIALOG_CLASS).length > 1;

        if (add) {
            document.body.classList.add(MODAL_OPEN_CLASS);
        } else if (!add && !hasOtherDialogs) {
            document.body.classList.remove(MODAL_OPEN_CLASS);
        }
    };

    const handleCloseButton = () => closeDialog(true);

    const closeDialog = (usedEscapeKey: boolean) => {
        if (onCloseValidation()) {
            setOpen(false);
            onCloseCallback();

            usedEscapeKey && onEsc();
        }
    };

    const [previousShow, setPreviousShow] = useState(show);
    if (show !== previousShow) {
        setOpen(show);
        toggleBodyClass(show);
        setPreviousShow(show);
    }

    useEsc(() => {
        if (!dialogWrapperRef || !dialogWrapperRef.current) {
            return;
        }

        const dialogElement = dialogWrapperRef.current;
        const currentActiveElement = document.activeElement;

        // Only allow to close the dialog when the focus is inside the dialog
        if (!disableEsc && dialogElement.contains(currentActiveElement)) {
            closeDialog(true);
        }
    });

    const handleAnimationComplete = (definition: AnimationDefinition) => {
        // The animation complete callback is invoked too when closing the dialog.
        // For that we check the animation props like the "opacity" to skip focusing
        // on the way out when closing the dialog.
        // @ts-ignore
        if (definition.opacity === 0) {
            return;
        }

        const dialogElement = dialogWrapperRef.current;
        const currentActiveElement = document.activeElement;

        // Set the focus to the dialog if no element inside has focus already. Otherwise, focused
        // elements like inputs would lose their focus to the dialog.
        // Note that in order to focus the dialog itself, the tabindex has to be set on that element
        // IMPORTANT: make sure the dialog has a tabIndex prop
        if (!dialogElement?.contains(currentActiveElement)) {
            dialogWrapperRef?.current?.focus();
        }
    };

    const modalClasses = classNames('modal', 'show', className);

    const isSmallestDialog = bsSize === 'xs';

    const hasChildren = !!children;

    const modalDialogClasses = classNames(
        MODAL_DIALOG_CLASS,
        useOverflow && 'modal-overflow',
        bsSize === 'xs' && 'modal-xs',
        bsSize === 'sm' && 'modal-sm',
        bsSize === 'lg' && 'modal-lg',
        bsSize === 'xl' && 'modal-xl',
        bsSize === 'full' && 'modal-full-width',
        bsSize === 'fullwidth' && 'modal-full-width',
        bsSize === 'fullheight' && 'modal-full-height',
        bsSize === 'fullheight-lg' && 'modal-full-height modal-lg',
        bsSize === 'fullheight-xl' && 'modal-full-height modal-xl',
        bsSize === 'fullscreen' && 'modal-fullscreen'
    );

    const spring = {
        type: 'spring',
        damping: 33,
        stiffness: 500,
    };

    const springXs = {
        type: 'spring',
        damping: 25,
        stiffness: 400,
    };

    return ReactDOM.createPortal(
        <DialogContextProvider
            value={{ onClose: handleCloseButton, isSmallestDialog, showXsDialogBorders, showCloseButton }}
        >
            {/* @ts-ignore-next-line */}
            <AnimatePresence
                // Disable any initial animations on children that
                // are present when the component is first rendered
                initial={false}
                // Only render one component at a time.
                // The exiting component will finish its exit
                // animation before entering component is rendered
                exitBeforeEnter
            >
                {open && (
                    <div
                        {...remainingProps}
                        className={modalClasses}
                        role='dialog'
                        ref={dialogWrapperRef}
                        aria-label='dialog'
                        // Make sure it has a tabIndex to focus the dialog so the close on esc works.
                        // biome-ignore lint/a11y/noNoninteractiveTabindex: <explanation>
                        tabIndex={0}
                    >
                        <motion.div
                            initial={shouldReduceMotion ? false : { opacity: 0, y: '-50%' }}
                            animate={
                                shouldReduceMotion
                                    ? { opacity: 1 }
                                    : { opacity: 1, y: 0, transition: isSmallestDialog ? springXs : spring }
                            }
                            exit={shouldReduceMotion ? undefined : { opacity: 0, y: '-150%' }}
                            transition={shouldReduceMotion ? { duration: 0 } : { opacity: 0.2, y: 0.3 }}
                            onAnimationComplete={handleAnimationComplete}
                            // @ts-ignore
                            ref={setFocusTrapRef}
                            className={modalDialogClasses}
                            role='document'
                        >
                            <div className='modal-content'>
                                {hasChildren && children}
                                {!hasChildren && (
                                    <>
                                        {title && (
                                            <DialogHeader
                                                title={title}
                                                subtitle={subtitle}
                                                headerButtons={headerButtons}
                                            />
                                        )}
                                        {body && <DialogBody className={bodyClassName}>{body}</DialogBody>}
                                        {footer && <DialogFooter className={footerClassName}>{footer}</DialogFooter>}
                                    </>
                                )}
                            </div>
                        </motion.div>
                        <motion.div
                            initial={shouldReduceMotion ? false : { opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={shouldReduceMotion ? undefined : { opacity: 0 }}
                            transition={shouldReduceMotion ? { duration: 0 } : { duration: 0.1 }}
                            className='modal-backdrop'
                        />
                    </div>
                )}
            </AnimatePresence>
        </DialogContextProvider>,
        modalRoot
    );
};

Dialog.Title = DialogHeader;
Dialog.Body = DialogBody;
Dialog.Footer = DialogFooter;

Dialog.SIZE_XS = 'xs' as const;
Dialog.SIZE_SM = 'sm' as const;
Dialog.SIZE_MD = 'md' as const; // default
Dialog.SIZE_LG = 'lg' as const;
Dialog.SIZE_XL = 'xl' as const;
Dialog.SIZE_FULL = 'full' as const;
Dialog.SIZE_FULL_WIDTH = 'fullwidth' as const;
Dialog.SIZE_FULL_HEIGHT = 'fullheight' as const;
Dialog.SIZE_FULL_HEIGHT_LG = 'fullheight-lg' as const;
Dialog.SIZE_FULL_HEIGHT_XL = 'fullheight-xl' as const;
Dialog.SIZE_FULL_SCREEN = 'fullscreen' as const;

export default Dialog;
