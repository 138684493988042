/* eslint-disable max-len */
import { useState } from 'react';

import Switch from '../../../../../src/Switch';

export default () => {
    const [horizontalForm, setHorizontalForm] = useState(false);

    const handleToggleDirection = () => setHorizontalForm(value => !value);

    return (
        <div>
            <div className='row equal-height'>
                <div className='col-12 col-md-6 margin-bottom-15'>
                    <div className='panel panel-default panel-body height-100pct shadow-default'>
                        <legend className='display-flex justify-content-between'>
                            <span>Legend</span>
                            <div className='btn-group'>
                                <Switch
                                    onChange={handleToggleDirection}
                                    checked={horizontalForm}
                                    enabledText='Horizontal'
                                    disabledText='Horizontal'
                                />
                            </div>
                        </legend>
                        <form>
                            <fieldset>
                                <div
                                    className={`${horizontalForm ? 'display-grid grid-cols-2-max-content-1fr column-gap-20' : ''}`}
                                >
                                    <div
                                        className={`form-group ${horizontalForm ? 'form-group-horizontal display-grid grid-cols-subgrid grid-colspan-2' : ''}`}
                                    >
                                        <label htmlFor='inputEmail'>Email</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='inputEmail'
                                            placeholder='Email'
                                            autoComplete='username'
                                        />
                                    </div>
                                    <div
                                        className={`form-group ${horizontalForm ? 'form-group-horizontal display-grid grid-cols-subgrid grid-colspan-2' : ''}`}
                                    >
                                        <label htmlFor='inputPassword'>Password</label>
                                        <input
                                            type='password'
                                            className='form-control'
                                            id='inputPassword'
                                            placeholder='Password'
                                            autoComplete='current-password'
                                        />
                                    </div>
                                    <div
                                        className={`form-group ${horizontalForm ? 'form-group-horizontal display-grid grid-cols-subgrid grid-colspan-2' : ''}`}
                                    >
                                        <label htmlFor='textArea'>Textarea</label>
                                        <textarea className='form-control' rows={3} id='textArea' />
                                    </div>
                                </div>
                                <hr />
                                <div className='display-flex gap-10'>
                                    <div className='form-group flex-1-1'>
                                        <label htmlFor='numberControls'>Number with controls</label>
                                        <input
                                            id='numberControls'
                                            type='number'
                                            placeholder='123456789'
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='form-group flex-1-1'>
                                        <label htmlFor='numberNoControls'>Number without controls</label>
                                        <input
                                            id='numberNoControls'
                                            type='number'
                                            placeholder='123456789'
                                            className='form-control no-controls'
                                        />
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label>Radios</label>
                                    <div>
                                        <label className='radio' htmlFor='radio1'>
                                            <input type='radio' id='radio1' name='radioGroup' />
                                            <span className='radio-text'>
                                                <span>Option one</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div>
                                        <label className='radio' htmlFor='radio2'>
                                            <input type='radio' id='radio2' name='radioGroup' />
                                            <span className='radio-text'>
                                                <span>Option two</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='checkbox'>Checkbox</label>
                                    <div>
                                        <label className='checkbox'>
                                            <input id='checkbox' type='checkbox' />
                                            <span className='checkbox-text'>
                                                <span>Lorem ipsum dolor sit amet.</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='select'>Native Selects</label>
                                    <select className='form-control' id='select'>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </div>
                                <div className='form-group'>
                                    <div className='display-flex justify-content-end'>
                                        <button type='reset' className='btn btn-default margin-right-10'>
                                            Cancel
                                        </button>
                                        <button type='submit' className='btn btn-primary'>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>

                <div className='col-12 col-md-6 margin-bottom-15'>
                    <div className='panel panel-default panel-body height-100pct shadow-default'>
                        <form>
                            <div className='form-group'>
                                <label htmlFor='disabledInput'>Disabled input</label>
                                <input
                                    disabled
                                    className='form-control'
                                    id='disabledInput'
                                    type='text'
                                    placeholder='Disabled input here...'
                                />
                            </div>

                            <div className='form-group'>
                                <div className='form-group has-success'>
                                    <label htmlFor='inputSuccess'>Input success</label>
                                    <input type='text' className='form-control' id='inputSuccess' />
                                </div>

                                <div className='form-group has-warning'>
                                    <label htmlFor='inputWarning'>Input warning</label>
                                    <input type='text' className='form-control' id='inputWarning' />
                                </div>

                                <div className='form-group has-error'>
                                    <label htmlFor='inputError'>Input error</label>
                                    <input type='text' className='form-control' id='inputError' />
                                </div>
                            </div>
                            <div className='form-group'>
                                <label htmlFor='totalLength'>Total length</label>
                                <div className='input-group'>
                                    <input id='totalLength' className='form-control' type='text' defaultValue='6.52' />
                                    <div className='input-group-addon'>m</div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label htmlFor='height'>Height</label>
                                <div className='input-group'>
                                    <input id='height' className='form-control' type='text' defaultValue='2.55' />
                                    <div className='input-group-addon'>m</div>
                                </div>
                            </div>
                            <div className='form-group has-feedback has-error'>
                                <label htmlFor='totalLength2'>Total length</label>
                                <div className='input-group'>
                                    <input id='totalLength2' className='form-control' type='text' defaultValue='6.52' />
                                    <div className='input-group-addon'>m</div>
                                </div>
                            </div>
                            <div className='form-group has-feedback has-warning'>
                                <label htmlFor='height2'>Height</label>
                                <div className='input-group'>
                                    <input id='height2' className='form-control' type='text' defaultValue='2.55' />
                                    <div className='input-group-addon'>m</div>
                                </div>
                            </div>
                            <div className='form-group has-feedback has-success'>
                                <label htmlFor='totalLength3'>Total length</label>
                                <div className='input-group'>
                                    <input id='totalLength3' className='form-control' type='text' defaultValue='6.52' />
                                    <div className='input-group-addon'>m</div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label htmlFor='height3'>Height</label>
                                <div className='input-group'>
                                    <input
                                        id='height3'
                                        className='form-control'
                                        type='text'
                                        defaultValue='2.55'
                                        disabled
                                    />
                                    <div className='input-group-addon'>m</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className='row equal-height'>
                <div className='col-12 col-md-6 margin-bottom-15'>
                    <div className='panel panel-default panel-body height-100pct shadow-default'>
                        <form>
                            <div className='form-group'>
                                <label
                                    htmlFor='defaultInput'
                                    className='display-inline-flex flex-wrap justify-content-between width-100pct'
                                >
                                    Default input
                                    <span className='text-italic'>Some input info for the user</span>
                                </label>
                                <input id='defaultInput' type='text' className='form-control' defaultValue='rio' />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='inputAddonLabel'>Input addon label</label>
                                <div className='input-group'>
                                    <span className='input-group-addon input-group-addon-label'>Label</span>
                                    <input id='inputAddonLabel' className='form-control' />
                                    <span className='input-group-addon input-group-addon-label'>Label</span>
                                </div>
                            </div>

                            <div className='form-group'>
                                <label htmlFor='InputAddons'>Input addons</label>
                                <div className='input-group'>
                                    <span className='input-group-addon'>
                                        <span className='rioglyph rioglyph-start' />
                                    </span>
                                    <input id='InputAddons' className='form-control' />
                                    <span className='input-group-addon'>.00</span>
                                </div>
                            </div>

                            <div className='form-group'>
                                <label htmlFor='inputGroups'>Input groups</label>
                                <div className='input-group'>
                                    <span className='input-group-addon'>
                                        <span className='rioglyph rioglyph-time' />
                                    </span>
                                    <input id='inputGroups' type='text' className='form-control' />
                                    <span className='input-group-btn'>
                                        <button className='btn btn-default' type='button'>
                                            Button
                                        </button>
                                    </span>
                                </div>
                            </div>

                            <div className='form-group'>
                                <div className='input-group'>
                                    <span className='input-group-addon'>
                                        <span className='rioglyph rioglyph-search' />
                                    </span>
                                    <input type='text' className='form-control' placeholder='Search' />
                                    <span className='input-group-btn'>
                                        <button className='btn btn-primary' type='button'>
                                            Button
                                        </button>
                                    </span>
                                </div>
                            </div>

                            <div className='form-group'>
                                <label htmlFor='inputWithDropdown'>Input with dropdown</label>
                                <div className='input-group'>
                                    <input id='inputWithDropdown' className='form-control' />
                                    <div className='input-group-btn'>
                                        <button
                                            type='button'
                                            className='btn btn-default dropdown-toggle'
                                            data-toggle='dropdown'
                                        >
                                            Action
                                            <span className='caret' />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='form-group'>
                                <div className='input-group'>
                                    <div className='input-group-btn'>
                                        <button
                                            type='button'
                                            className='btn btn-default dropdown-toggle'
                                            data-toggle='dropdown'
                                        >
                                            Action
                                            <span className='caret' />
                                        </button>
                                    </div>
                                    <input className='form-control' />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className='col-12 col-md-6 margin-bottom-15'>
                    <div className='panel panel-default panel-body height-100pct shadow-default'>
                        <form>
                            <div className='form-group has-feedback has-error'>
                                <label
                                    htmlFor='defaultInput2'
                                    className='display-inline-flex flex-wrap justify-content-between width-100pct'
                                >
                                    Default input
                                    <span className='text-italic'>Some input info for the user</span>
                                </label>
                                <input
                                    id='defaultInput2'
                                    type='text'
                                    className='form-control'
                                    defaultValue='invalid input'
                                />
                                <span className='form-control-feedback rioglyph rioglyph-error-sign' />
                                <span className='help-block'>
                                    <span>This is an error message</span>
                                </span>
                            </div>

                            <div className='form-group has-feedback has-warning'>
                                <label htmlFor='inputWithWarning'>Input addon label</label>
                                <div className='input-group'>
                                    <span className='input-group-addon input-group-addon-label'>Label</span>
                                    <div className='form-control-feedback-wrapper'>
                                        <input id='inputWithWarning' className='form-control' />
                                        <span className='form-control-feedback rioglyph rioglyph-warning-sign' />
                                        <span className='help-block'>
                                            <span>This is a warning message</span>
                                        </span>
                                    </div>
                                    <span className='input-group-addon input-group-addon-label'>Label</span>
                                </div>
                            </div>

                            <div className='form-group has-feedback has-error'>
                                <label htmlFor='inputWithError'>Input addons</label>
                                <div className='input-group'>
                                    <span className='input-group-addon'>
                                        <span className='rioglyph rioglyph-start' />
                                    </span>
                                    <div className='form-control-feedback-wrapper'>
                                        <input id='inputWithError' className='form-control' />
                                        <span className='form-control-feedback rioglyph rioglyph-error-sign' />
                                    </div>
                                    <span className='input-group-addon'>.00</span>
                                </div>
                            </div>

                            <div className='form-group has-feedback has-error'>
                                <label htmlFor='inputGroupsWithError'>Input groups</label>
                                <div className='input-group'>
                                    <span className='input-group-addon'>
                                        <span className='rioglyph rioglyph-time' />
                                    </span>
                                    <input id='inputGroupsWithError' type='text' className='form-control' />
                                    <span className='input-group-btn'>
                                        <button className='btn btn-default' type='button'>
                                            Button
                                        </button>
                                    </span>
                                </div>
                            </div>

                            <div className='form-group has-feedback has-error'>
                                <div className='input-group'>
                                    <span className='input-group-addon'>
                                        <span className='rioglyph rioglyph-search' />
                                    </span>
                                    <input type='text' className='form-control' placeholder='Search' />
                                    <span className='input-group-btn'>
                                        <button className='btn btn-primary' type='button'>
                                            Button
                                        </button>
                                    </span>
                                </div>
                            </div>

                            <div className='form-group has-feedback has-error'>
                                <label htmlFor='inputDropdownWithError'>Input with dropdown</label>
                                <div className='input-group'>
                                    <input id='inputDropdownWithError' className='form-control' />
                                    <div className='input-group-btn'>
                                        <button
                                            type='button'
                                            className='btn btn-default dropdown-toggle'
                                            data-toggle='dropdown'
                                        >
                                            Action
                                            <span className='caret' />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='form-group has-feedback has-error'>
                                <div className='input-group'>
                                    <div className='input-group-btn'>
                                        <button
                                            type='button'
                                            className='btn btn-default dropdown-toggle'
                                            data-toggle='dropdown'
                                        >
                                            Action
                                            <span className='caret' />
                                        </button>
                                    </div>
                                    <input className='form-control' />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
