import React, { useEffect, useMemo, useRef } from 'react';

// See www.joshwcomeau.com/snippets/react-hooks/use-timeout/

/**
 * A custom hook for handling timeout events.
 *
 * @param callback The callback function to execute after the timeout.
 * @param delay The duration of the timeout in milliseconds. Pass `null` to cancel the timeout.
 *
 * @returns A mutable ref object to manage the timeout ID.
 */
const useTimeout = (callback: () => void, delay: number | null): React.MutableRefObject<number | null> => {
    const timeoutRef = useRef<number | null>(null);
    const savedCallback = useRef<() => void>(callback);

    // Update the saved callback when the callback prop changes
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the timeout when the delay changes
    useEffect(() => {
        const tick = () => savedCallback.current();

        // If delay is a number, set up the timeout
        if (typeof delay === 'number') {
            timeoutRef.current = window.setTimeout(tick, delay);

            // Cleanup function to clear the timeout when delay changes or component unmounts
            return () => {
                if (timeoutRef.current !== null) {
                    window.clearTimeout(timeoutRef.current);
                }
            };
        }
    }, [delay]);

    return timeoutRef;
};

export const useChainedTimeout = () => {
    const MAX_DELAY_MS = 2 ** 31 - 1;

    const setChainedTimeout = (ref: React.MutableRefObject<any>, fn: () => void, timeoutAtMs: number) => {
        const delayMs = timeoutAtMs - Date.now();

        ref.current =
            delayMs <= MAX_DELAY_MS
                ? setTimeout(fn, delayMs)
                : setTimeout(() => setChainedTimeout(ref, fn, timeoutAtMs), MAX_DELAY_MS);
    };

    const handleRef = useRef<any>(null);

    const { set, clear } = useMemo(() => {
        const clear = () => clearTimeout(handleRef.current);

        const set = (fn: () => void, delayMs = 0): void => {
            clear();

            if (delayMs <= MAX_DELAY_MS) {
                // For simplicity, if the timeout is short, just set a normal timeout.
                handleRef.current = setTimeout(fn, delayMs);
            } else {
                setChainedTimeout(handleRef, fn, Date.now() + delayMs);
            }
        };

        return {
            set,
            clear,
        };
    }, []);

    useEffect(() => {
        return () => {
            clear();
        };
    }, [clear]);

    return {
        set,
        clear,
        handleRef,
    };
};

export default useTimeout;
