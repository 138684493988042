import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';

import { icons } from '../../../utils/icons';
import { getNames } from '../../../utils/mapUtils';

const humanIcons = [...getNames(icons.humans)] as string[];

export const RioglyphAnimated = () => (
    <div className='uikit-icons'>
        <Description headline='Animated icons' headlineType='h3' anchor='animatedIcons' />
        <Playground example={rioglyphAnimatedIconsExample} />
    </div>
);

const rioglyphAnimatedIconsExample = (
    <div className='display-flex flex-wrap gap-50'>
        <div>
            <Title>Spinning icons</Title>
            <div className='display-flex flex-wrap gap-10'>
                <div className='text-size-h2'>
                    <span className='rioglyph rioglyph-refresh spinning text-size-150pct' />
                    <span className='rioglyph rioglyph-climate-control spinning text-size-150pct' />
                    <span className='rioglyph rioglyph-cog spinning text-size-150pct' />
                    <span className='rioglyph rioglyph-spinner spinning text-size-150pct' />
                    <span className='rioglyph rioglyph-tire spinning text-size-150pct' />
                </div>
            </div>
        </div>
        <div>
            <Title>Pulsing icons</Title>
            <div className='display-flex flex-wrap gap-10'>
                <div className='text-size-h2'>
                    {humanIcons.map(icon => (
                        <span className={`rioglyph ${icon} pulsing text-size-150pct`} key={icon} />
                    ))}
                </div>
            </div>
        </div>
    </div>
);
