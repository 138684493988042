import React, { useState } from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

import Dialog, { type BaseDialogProps } from './Dialog';
import ResponsiveVideo from '../video/ResponsiveVideo';
import ImagePreloader from '../preloader/ImagePreloader';
import Spinner from '../spinner/Spinner';

// TODO: add keyboard support left/right

export type MediaDialogMedia = {
    /**
     * Defines the media type that is going to be displayed.
     *
     * Possible values are: `image` and `video`.
     */
    type: 'image' | 'video';

    /**
     * The source location for the media data.
     */
    src: string;

    /**
     * The name or title of the media used for the dialogs title.
     */
    title: string | React.ReactNode;

    /**
     * Additional media information used for the dialogs subtitle.
     */
    subtitle?: string | React.ReactNode;

    /**
     * Only relevant for videos where the aspect of the video is defined.
     *
     * Possible values are: `4by3` and `16by9`.
     * @default '16by9'
     */
    aspectRatio?: '4by3' | '16by9';

    /**
     * Additional classes for the image element.
     */
    className?: string;
};

export type MediaDialogProps = BaseDialogProps & {
    /**
     * List of media objects.
     */
    media: MediaDialogMedia[];

    /**
     * The button text for switching to the previous media if there are multiple.
     */
    previousButtonText: string | React.ReactNode;

    /**
     * Callback function for when the previous button is clicked.
     * @param newIndex
     * @returns
     */
    previousButtonCallback?: (newIndex: number) => void;

    /**
     * The button text for switching to the next media if there are multiple.
     */
    nextButtonText: string | React.ReactNode;

    /**
     * Callback function for when the next button is clicked.
     * @param newIndex
     * @returns
     */
    nextButtonCallback?: (newIndex: number) => void;
};

const MediaDialog = (props: MediaDialogProps) => {
    const {
        show = false,
        onClose = noop,
        disableEsc = false,
        useOverflow = false,
        bsSize = 'lg',
        media = [],
        previousButtonText = 'Prev',
        previousButtonCallback = noop,
        nextButtonText = 'Next',
        nextButtonCallback = noop,
        className,
        ...remainingProps
    } = props;

    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

    const handlePrevious = () => {
        const oldIndex = currentMediaIndex;
        const newIndex = oldIndex === 0 ? props.media.length - 1 : oldIndex - 1;
        setCurrentMediaIndex(newIndex);
        previousButtonCallback(newIndex);
    };

    const handleNext = () => {
        const oldIndex = currentMediaIndex;
        const newIndex = oldIndex === props.media.length - 1 ? 0 : oldIndex + 1;
        setCurrentMediaIndex(newIndex);
        nextButtonCallback(newIndex);
    };

    const renderFallback = () => {
        return (
            <div className='text-center text-size-h3'>
                <span className='rioglyph rioglyph-missing text-size-200pct text-color-gray' />
            </div>
        );
    };

    const renderImage = (mediaData: MediaDialogMedia) => {
        const imageClassNames = classNames('img-responsive', mediaData.className && mediaData.className);

        return (
            // @ts-ignore
            <ImagePreloader src={mediaData.src} className={imageClassNames}>
                {({ status, image }: { status: 'PENDING' | 'LOADED' | 'FAILED'; image: { src: string } }) => {
                    if (status === ImagePreloader.STATUS_LOADED) {
                        // When image has been loaded, render the image tag
                        return <img className='ani-fade-in' key={image.src} src={image.src} />;
                    }

                    if (status === ImagePreloader.STATUS_FAILED) {
                        return renderFallback();
                    }

                    return <Spinner isInverse={false} />;
                }}
            </ImagePreloader>
        );
    };

    const renderVideo = (mediaData: MediaDialogMedia) => {
        const videoClassNames = classNames(mediaData.className && mediaData.className);
        return (
            <div className='flex-1-0'>
                <ResponsiveVideo
                    src={mediaData.src}
                    aspectRatio={mediaData.aspectRatio ?? '16by9'}
                    className={videoClassNames}
                />
            </div>
        );
    };

    const renderMediaContent = (mediaData = {} as MediaDialogMedia) => {
        if (mediaData.type === MediaDialog.MEDIA_TYPE_VIDEO) {
            return renderVideo(mediaData);
        }

        // image media is default
        return mediaData.src && renderImage(mediaData);
    };

    const dialogClassName = classNames('media-dialog', className && className);
    const bodyClassNames = classNames('padding-0');
    const mediaContentClassNames = classNames('media-content', 'content-center');

    const mediaTitle = media[currentMediaIndex].title;
    const mediaSubtitle = media[currentMediaIndex].subtitle || null;

    return (
        <Dialog
            {...remainingProps}
            show={show}
            title={mediaTitle}
            subtitle={mediaSubtitle}
            body={<div className={mediaContentClassNames}>{renderMediaContent(media[currentMediaIndex])}</div>}
            footer={
                media?.length > 1 && (
                    <>
                        <button type='button' className='btn btn-primary btn-link pull-left' onClick={handlePrevious}>
                            <span className='rioglyph rioglyph-chevron-left' />
                            {previousButtonText}
                        </button>
                        <div>{`${currentMediaIndex + 1} / ${props.media.length}`}</div>
                        <button type='button' className='btn btn-primary btn-link btn-icon-right' onClick={handleNext}>
                            <span className='rioglyph rioglyph-chevron-right' />
                            {nextButtonText}
                        </button>
                    </>
                )
            }
            onClose={onClose}
            className={dialogClassName}
            bodyClassName={bodyClassNames}
            disableEsc={disableEsc}
            useOverflow={useOverflow}
            bsSize={bsSize}
        />
    );
};

MediaDialog.MEDIA_TYPE_IMAGE = 'image' as const;
MediaDialog.MEDIA_TYPE_VIDEO = 'video' as const;

export default MediaDialog;
