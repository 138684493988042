import { Playground } from '../../../components/playground/Playground';
import { PlaygroundColors } from '../../../components/playground/PlaygroundColors';
import { PlaygroundClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';

import { colors, getColorNames } from '../../../utils/colors';
import { getRandomIcon } from '../../../utils/icons';
import { Note } from '../../../components/Note';

export const Labels = () => (
    <div>
        <Description headline='Labels' anchor='labels' />
        <Playground
            example={labels}
            classes={labelsClasses}
            availableColors={
                <PlaygroundColors bgDefault bgPrimary bgSecondary bgInfo bgSuccess bgWarning bgDanger bgMuted />
            }
        />
        <Note>For having icons in labels, the text must be wrapped with a span to ensure the correct spacing.</Note>
    </div>
);

const eachColor = ['default', ...getColorNames(colors.brand, colors.status), 'muted'];

const labels = (
    <>
        <p className='margin-bottom-5 margin-top-0'>
            Labels are small, colored indicators used to represent statuses, categories, or highlights in the UI.
        </p>
        <ul className='space-y-5 margin-bottom-20'>
            <li>
                The <b>standard labels</b> have a light background and are ideal for subtle status indications that
                blend naturally with the interface.
            </li>
            <li>
                The <b>filled labels</b> use a bold background color, making them more prominent—best suited for drawing
                attention to critical statuses or highlighting important information.
            </li>
        </ul>
        <div className='row'>
            <div className='col-lg-6 margin-bottom-25'>
                <h5>Standard labels</h5>
                <div className='display-flex flex-wrap gap-10'>
                    {eachColor.map((color, index) => (
                        <div className={`label label-${color}`} key={index}>
                            {color}
                        </div>
                    ))}
                </div>
            </div>

            <div className='col-lg-6 margin-bottom-25'>
                <h5>Filled labels</h5>
                <div className='display-flex flex-wrap gap-10'>
                    {eachColor.map((color, index) => (
                        <div className={`label label-${color} label-filled`} key={index}>
                            {color}
                        </div>
                    ))}
                </div>
            </div>

            <div className='col-lg-6 margin-bottom-25'>
                <h5>Labels width icons</h5>
                <div className='display-flex flex-wrap gap-10'>
                    {eachColor.map((color, index) => (
                        <div className={`label label-${color}`} key={index}>
                            <span className={`rioglyph ${getRandomIcon()}`} />
                            <span>{color}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div className='col-lg-6 margin-bottom-25'>
                <h5>Filled labels width icons</h5>
                <div className='display-flex flex-wrap gap-10'>
                    {eachColor.map((color, index) => (
                        <div className={`label label-${color} label-filled`} key={index}>
                            <span>{color}</span>
                            <span className={`rioglyph ${getRandomIcon()}`} />
                        </div>
                    ))}
                </div>
            </div>

            <div className='col-lg-6 margin-bottom-25'>
                <h5>Condensed labels</h5>
                <div className='display-flex flex-wrap gap-10'>
                    {eachColor.map((color, index) => (
                        <div className={`label label-${color} label-condensed`} key={index}>
                            {color}
                        </div>
                    ))}
                </div>
            </div>

            <div className='col-lg-6 margin-bottom-25'>
                <h5>Condensed and filled labels</h5>
                <div className='display-flex flex-wrap gap-10'>
                    {eachColor.map((color, index) => (
                        <div className={`label label-${color} label-filled label-condensed`} key={index}>
                            {color}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </>
);

const labelsClasses = (
    <>
        <PlaygroundClasses label='Colors' prefix='label-' classes={eachColor} />
        <PlaygroundClasses label='Variants' prefix='label-' classes={['filled', 'condensed']} />
    </>
);
