import React from 'react';
import classNames from 'classnames';
import { ToastContainer } from 'react-toastify';

// Note:
// With the new library we have to decide to either stack them all or to show a limit and one they are gone,
// the remaining notifications slide in.

export type NotificationsContainerProps = {
    /**
     * Defines whether the notification are stacked.
     *
     * @deprecated
     */
    stacked?: boolean;
};

const NotificationsContainer = ({ stacked }: NotificationsContainerProps) => {
    const wrapperClasses = classNames('notification-container-wrapper', stacked && 'stacked');
    return (
        <div className={wrapperClasses}>
            <ToastContainer className='notification-container' stacked={false} limit={5} />
        </div>
    );
};

export default NotificationsContainer;
