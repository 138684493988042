import { version } from '../../version.json';
import { getConsoleStyle } from './styledLogs';

const versionsListUrl = 'https://uikit.developers.rio.cloud/versionsList.json';

export const checkForReleaseVersion = async () => {
    const logStyled = (message: string) => console.log('%c%s', getConsoleStyle(), message);

    try {
        const response = await fetch(versionsListUrl);
        const { latest_release } = await response.json();

        if (latest_release > version) {
            logStyled(
                `🆕 You are using version ${version} of the UIKIT while the latest released version is ` +
                    `${latest_release}. Please consider updating asap.`
            );
        }
    } catch (error) {
        logStyled('Failed to fetch UIKIT version list.');
    }
};
