import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';

import { icons } from '../../../utils/icons';
import { getNames } from '../../../utils/mapUtils';
const vehicleIcons = [...getNames(icons.vehicles)] as string[];

export const RioglyphVehicle = () => (
    <div className='uikit-icons'>
        <Description headline='Vehicle icons' headlineType='h3' anchor='vehicleIcons' />
        <Playground example={rioglyphVehicleIconsExample} />
    </div>
);

const rioglyphVehicleIconsExample = (
    <div className='display-flex flex-wrap gap-50'>
        <div>
            <Title>Vehicle icons</Title>
            <div className='display-flex flex-wrap gap-10 border border-bottom-only'>
                {vehicleIcons.map(icon => (
                    <span className={`rioglyph ${icon} text-size-h1`} key={icon} />
                ))}
            </div>
        </div>
        <div>
            <Title>Baseline vehicle icons</Title>
            <div className='display-flex flex-wrap gap-10 border border-bottom-only'>
                {vehicleIcons.map(icon => (
                    <span className={`rioglyph ${icon}-baseline text-size-h1`} key={icon} />
                ))}
            </div>
        </div>
    </div>
);
