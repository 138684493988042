import React, { type ForwardedRef, forwardRef, type MouseEvent, type ReactNode } from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

import Button from '../button/Button';

export type AppNavigationBarProps = {
    /**
     * The title for the content that is shown.
     */
    title?: string | ReactNode;

    /**
     * Defines the size of the title. Possible values are 'md' and 'lg'.
     *
     * @default 'md'
     */
    titleSize?: 'md' | 'lg';

    /**
     * Adds a subtitle below the title.
     */
    subtitle?: string | ReactNode;

    /**
     * Right side area for additional options of buttons like filter etc. that is relevant for the shown content..
     */
    right?: ReactNode;

    backButtonText?: string | ReactNode;

    /**
     * Add a light bottom border to the wrapper element.
     *
     * @default false
     */
    border?: boolean;

    /**
     * Callback function for when the back navigation button is clicked.
     */
    onNavigateBack?: (event: MouseEvent<HTMLSpanElement>) => void;

    /**
     * Additional classes for the wrapper element.
     */
    className?: string;
};

export const AppNavigationBar = forwardRef((props: AppNavigationBarProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
        className,
        title,
        right = <span />,
        backButtonText = '',
        titleSize = 'md',
        subtitle,
        border = false,
        onNavigateBack = noop,
        ...remainingProps
    } = props;

    const navigationClasses = classNames(
        'AppNavigationBar',
        'width-100pct height-45 user-select-none padding-x-10',
        'display-grid grid-stack align-items-center',
        titleSize === 'md' && 'text-size-14',
        titleSize === 'lg' && 'text-size-16',
        border && 'border border-bottom-only border-color-lighter',
        className
    );

    return (
        <div ref={ref} {...remainingProps} className={navigationClasses}>
            <div className='grid-stack-item display-flex align-items-center justify-content-between'>
                <Button
                    bsStyle='muted'
                    className='text-color-darkest'
                    iconOnly={!backButtonText}
                    iconName='rioglyph-arrow-left'
                    onClick={onNavigateBack}
                >
                    {backButtonText}
                </Button>
                <div className='btn-toolbar gap-2'>{right}</div>
            </div>
            <div className='grid-stack-item text-center text-medium text-color-darker margin-x-25 padding-x-5 ellipsis-1'>
                <div className='ellipsis-1'>{title}</div>
                {subtitle && <div className='text-size-12 line-height-12 text-normal margin-top--2'>{subtitle}</div>}
            </div>
        </div>
    );
});

export default AppNavigationBar;
