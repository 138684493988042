// @ts-ignore-next-line importsNotUsedAsValues
import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';

import StateButton, { type StateButtonProps } from './StateButton';
import StateIcon from './StateIcon';
import type { BaseStateProps } from './BaseStateProps';
import type { TextAlignment } from '../../values/TextAlignment';
import { hasPaddingClass } from '../../utils/hasUtilityClass';

const justify: Record<TextAlignment, string> = {
    left: 'justify-content-start',
    center: 'justify-content-center',
    right: 'justify-content-end',
};

const renderButtons = (buttons: StateButtonProps[]) => {
    if (isEmpty(buttons)) {
        return;
    }

    if (buttons.length === 1) {
        return (
            <div className='btn-toolbar margin-top-20'>
                <StateButton {...buttons[0]} />
            </div>
        );
    }

    if (buttons.length === 2) {
        return (
            <div className='btn-toolbar margin-top-20 justify-content-center-xs'>
                {buttons.map((buttonProps, index) => (
                    <StateButton key={index} {...buttonProps} />
                ))}
            </div>
        );
    }

    return (
        <>
            <div className='btn-toolbar margin-top-20 justify-content-center-xs'>
                <StateButton key='1' {...buttons[0]} />
                <StateButton key='2' {...buttons[1]} />
            </div>
            <div className='btn-toolbar'>
                {buttons.map((buttonProps, index) => {
                    return index > 1 && <StateButton key={index} {...buttonProps} />;
                })}
            </div>
        </>
    );
};

export type CustomStateProps = BaseStateProps;

const CustomState = (props: PropsWithChildren<CustomStateProps>) => {
    const {
        icon,
        icons = [],
        image,
        headline,
        message,
        buttons = [],
        alignment = 'center',
        fullWidth = false,
        condensed = false,
        outerClassName = '',
        innerClassName = '',
        children,
        ...remainingProps
    } = props;

    const wrapperClasses = classNames(
        'margin-0',
        'width-100pct',
        !fullWidth && 'max-width-600',
        'panel-default',
        'panel',
        !hasPaddingClass(outerClassName) && condensed ? 'padding-10' : 'padding-15',
        outerClassName
    );

    const innerClassNames = classNames(
        'display-flex',
        'flex-column',
        'align-items-center',
        'text-center',
        !hasPaddingClass(innerClassName) && condensed ? 'padding-15' : 'padding-25',
        innerClassName
    );

    const iconClasses = classNames(condensed ? 'text-size-16 margin-bottom-10' : 'text-size-h2 margin-bottom-20');

    const iconsClasses = classNames(
        condensed ? 'text-size-16 margin-bottom-10' : 'text-size-h2 margin-bottom-20',
        'display-flex',
        'align-items-center',
        'max-width-100pct'
    );

    const headlineClassNames = classNames(
        condensed ? 'text-size-h4' : 'text-size-h3 text-size-h2-xl',
        'line-height-125rel',
        'text-color-dark',
        'text-medium',
        'width-500',
        'max-width-100pct',
        'text-wrap-balance'
    );

    const renderIcon = (
        <>
            {icon && (
                <div className={iconClasses}>
                    <StateIcon name={icon} />
                </div>
            )}
        </>
    );

    const renderIcons = (
        <div className={iconsClasses}>
            {icons.map((iconProps, index) => (
                <StateIcon key={index} {...iconProps} />
            ))}
        </div>
    );

    const renderChildren = <div className='width-500 max-width-100pct margin-top-25'>{children}</div>;

    const renderHeadline = <div className={headlineClassNames}>{headline}</div>;

    const renderMessage = (
        <div className='text-color-dark margin-top-15 width-500 max-width-100pct text-wrap-balance'>{message}</div>
    );

    const singleOrMultiIcon = isEmpty(icons) ? renderIcon : renderIcons;

    const imageOrIcon = image ? image : singleOrMultiIcon;

    return (
        <div className={`display-flex ${justify[alignment]} max-width-100pct`} {...remainingProps}>
            <div className={wrapperClasses}>
                <div className={innerClassNames}>
                    {imageOrIcon}
                    {headline && renderHeadline}
                    {message && renderMessage}
                    {renderButtons(buttons)}
                    {children && renderChildren}
                </div>
            </div>
        </div>
    );
};

export default CustomState;
