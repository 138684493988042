// @ts-ignore-next-line importsNotUsedAsValues
import React, { type ReactNode } from 'react';

type ListMenuHeaderProps = {
    group?: string | ReactNode;
    badge?: string | ReactNode;
    badgeType?: 'muted' | 'success' | 'info' | 'warning' | 'danger';
    groupNavItem?: string | ReactNode;
    className?: string;
};

const ListMenuHeader = ({ group, badge, badgeType = 'muted', groupNavItem, className = '' }: ListMenuHeaderProps) => {
    if (!group && !groupNavItem) {
        return null;
    }

    return (
        <li className='ListMenuHeader'>
            {groupNavItem ? groupNavItem : <span className={className}>{group}</span>}
            {badge && <span className={`badge badge-${badgeType}`}>{badge}</span>}
        </li>
    );
};

export default ListMenuHeader;
