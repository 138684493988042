// inspired from https://tobbelindstrom.com/blog/resize-observer-hook/

import { useRef, useLayoutEffect, useState, useCallback, type MutableRefObject } from 'react';

/**
 * Custom hook for observing element resize.
 * @returns
 */
const useResizeObserver = <T extends HTMLElement = HTMLDivElement>(): [
    MutableRefObject<T | null>,
    ResizeObserverEntry | null,
    { blockSize: number | undefined; inlineSize: number | undefined },
] => {
    const [observerEntry, setObserverEntry] = useState<ResizeObserverEntry | null>(null);

    const nodeRef = useRef<T | null>(null);

    const observer = useRef<ResizeObserver | null>(null);

    const disconnect = useCallback(() => observer.current?.disconnect(), []);

    const observe = useCallback(() => {
        observer.current = new ResizeObserver(([entry]) => setObserverEntry(entry));
        if (nodeRef.current) {
            observer.current.observe(nodeRef.current);
        }
    }, [nodeRef]);

    useLayoutEffect(() => {
        observe();
        return () => disconnect();
    }, [disconnect, observe]);

    return [
        nodeRef,
        observerEntry,
        {
            blockSize: observerEntry?.borderBoxSize?.at(0)?.blockSize,
            inlineSize: observerEntry?.borderBoxSize?.at(0)?.inlineSize,
        },
    ];
};
export default useResizeObserver;
