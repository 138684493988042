import React, { useState, forwardRef, type ForwardedRef } from 'react';
import omit from 'lodash/fp/omit';
import isNil from 'lodash/fp/isNil';
import isEmpty from 'lodash/fp/isEmpty';
import classNames from 'classnames';
import isFunction from 'lodash/fp/isFunction';
import noop from 'lodash/fp/noop';
import InputMask from 'react-input-mask';

import usePrevious from '../../hooks/usePrevious';

export const DEFAULT_TYPE = 'text';
export const SUPPORTED_TYPES = ['text', 'password', 'email'];

export type ClearableInputProps = {
    /**
     * Gives the input element the id.
     */
    id?: string;

    /**
     * Gives the input element a name.
     */
    name?: string;

    /**
     * The translated text that shall be shown when the input is empty.
     */
    placeholder?: string | React.ReactNode;

    /**
     * Defines the type of the input itself.
     *
     * There are three types supported:
     * `text`, `email`, `password`.
     */
    type?: 'text' | 'password' | 'email';

    /**
     * Initial value of the ClearableInput. Pass this prop if you want to use this
     * component as an uncontrolled component.
     */
    defaultValue?: string;

    /**
     * Value of the ClearableInput. Pass this prop if you want to use this
     * component as a controlled component.
     */
    value?: string;

    /**
     * Defines the maximum amount of characters that can be entered.
     */
    maxLength?: number;

    /**
     * Defines the tab index to be added to the input element.
     */
    tabIndex?: number;

    /**
     * Defined whether or not the input has the error styling.
     */
    hasError?: boolean;

    /**
     * The callback ref for the underlying input. Alternatively, use can use a forward ref.
     */
    inputRef?: React.RefObject<HTMLInputElement>;

    /**
     * Sets autocomplete value for autosuggest forms.
     */
    autoComplete?: string;

    /**
     * Callback function for when the value changes. Receives new value as an argument.
     * @param newValue
     * @param event
     * @returns
     */
    onChange?: (
        newValue: string,
        event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLSpanElement>
    ) => void;

    /**
     * Callback function which gets triggered when the input looses the focus.
     * @param event
     * @returns
     */
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;

    /**
     * Callback function which gets triggered when the input gains the focus.
     * @param event
     * @returns
     */
    onFocus?: (event: React.MouseEvent<HTMLSpanElement>) => void;

    /**
     * Callback function for when the value is cleared via the clear button.
     * @param event
     * @returns
     */
    onClear?: (event: React.MouseEvent<HTMLSpanElement>) => void;

    /**
     * Callback function for every key pressed including `Enter` key.
     * @param event
     * @returns
     */
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;

    /**
     * Callback triggered when clicked into the input to react on it like when using an auto suggest dropdown
     * @param event
     * @returns
     */
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;

    /**
     * The mask prop will allow to use the component with an input mask.
     * It defines the pattern that should be followed.
     *
     * For more details on masking, checkout the third party documentation for the
     * input mask here: {@link https://github.com/sanniassin/react-input-mask}
     *
     * Simple masks can be defined as strings.
     *
     * The following characters will define mask format:
     *
     * Character: "9" 	= allowed input: "0-9"
     *
     * Character: "a" 	= allowed input: "a-z, A-Z"
     *
     * Character: "*" 	= allowed input: 0-9, a-z, A-Z
     *
     * Any format character can be escaped with a backslash.
     *
     * @example
     * '-- *** *** ***' or '+4\\9 99 999 99'
     *
     * @example
     * ['0', '0', /[0-9]/, ' ', /[a-zA-Z]/]
     *
     */
    mask?: string | (string | RegExp)[];

    /**
     * Placeholder to cover unfilled parts of the mask.
     *
     * @default '_'
     */
    maskPlaceholder?: string | null;

    /**
     * Disabled the input component.
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * Whether mask prefix and placeholder should be displayed when input is empty and
     * has no focus.
     *
     * @default false
     */
    alwaysShowMask?: boolean;

    /**
     * Additional classes to be set on the input element.
     */
    inputClassName?: string;

    /**
     * Additional classes to be set on the wrapper element.
     */
    className?: string;
};

type ChildrenProp = {
    /**
     * Providing a function enables the render props approach. The function gets the input props
     * passed and is responsible for rendering the custom `input` component.
     * @param props
     * @returns
     */
    children?: (props: React.InputHTMLAttributes<HTMLInputElement>) => React.ReactNode;
};

const hasValue = (value: unknown) => !isEmpty(`${value}`);

const ClearableInput = forwardRef((props: ClearableInputProps & ChildrenProp, ref: ForwardedRef<HTMLInputElement>) => {
    const {
        type = DEFAULT_TYPE,
        defaultValue,
        value,
        maxLength,
        tabIndex = 0,
        hasError = false,
        inputRef,
        autoComplete,
        onChange = noop,
        onBlur = noop,
        onFocus = noop,
        onClear = noop,
        onKeyPress = noop,
        onClick = noop,
        mask,
        maskPlaceholder = '_',
        alwaysShowMask = false,
        inputClassName = '',
        disabled = false,
        className = '',
        children,
        ...remainingProps
    } = props;

    const initialValue = value || defaultValue || '';

    const [inputValue, setInputValue] = useState(initialValue);
    const [showClear, setShowClear] = useState(hasValue(initialValue));

    const isControlled = !isNil(value);

    const hasMask = !!mask;

    // Handles new input value and saves it in the local state.
    // The value stored in the state is used for rendering.
    const changeInternalValue = (newValue = '') => {
        // this is there to prevent that onChange and UNSAFE_componentWillReceiveProps set state at the same time
        if (inputValue === newValue) {
            return;
        }

        setInputValue(newValue);
        setShowClear(hasValue(newValue));
    };

    // important for when used as a controlled component and value changes from the outside.
    const previousValue = usePrevious(value);
    if (previousValue !== value) {
        changeInternalValue(value);
    }

    // only gets triggered on user interaction.
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        if (isControlled) {
            onChange(newValue, event);
        } else {
            changeInternalValue(newValue);
            onChange(newValue, event);
        }
    };

    // Will be triggered on every key press but also when pressing 'Enter' for example
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        onKeyPress(event);
    };

    const clearInputValue = (event: React.MouseEvent<HTMLSpanElement>) => {
        changeInternalValue('');
        onChange('', event);

        if (onClear) {
            onClear(event);
        }
    };

    const classes = classNames(
        'ClearableInput',
        'input-group',
        hasError && 'has-error',
        disabled && 'pointer-events-none',
        className && className
    );

    const inputClassNames = classNames(
        'form-control',
        inputClassName,
        showClear && 'withClearButton',
        hasMask && 'withInputMask',
        disabled && 'disabled'
    );

    const clearButtonClassNames = classNames('clearButton', !showClear && 'hide');

    const convertedType = type?.toLowerCase();
    const inputType = SUPPORTED_TYPES.indexOf(convertedType) !== -1 ? convertedType : DEFAULT_TYPE;

    const inputProps = {
        ...omit(['value', 'defaultValue', 'onClear'])(remainingProps),
        className: inputClassNames,
        autoComplete,
        type: inputType,
        value: inputValue,
        onChange: handleChange,
        onKeyPress: handleKeyPress,
        onBlur,
        onFocus,
        onClick,
        disabled,
        maxLength: hasMask ? undefined : maxLength,
        tabIndex,
        ref: inputRef || ref,
    } as any;

    const input = hasMask ? (
        <InputMask
            {...inputProps}
            disabled={disabled}
            mask={mask}
            maskPlaceholder={maskPlaceholder}
            alwaysShowMask={alwaysShowMask}
        />
    ) : (
        <input {...inputProps} />
    );

    return (
        <div className={classes}>
            {children && isFunction(children) ? children(inputProps) : input}
            <span className={clearButtonClassNames} onClick={clearInputValue}>
                <span className='clearButtonIcon rioglyph rioglyph-remove-sign' />
            </span>
        </div>
    );
});

export default ClearableInput;
