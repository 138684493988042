import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';

export const RioglyphPair = () => (
    <div className='uikit-icons'>
        <Description headline='Pair icons' headlineType='h3' anchor='pairIcons' />
        <Playground example={rioglyphPairIconsExample} />
    </div>
);

const rioglyphPairIconsExample = (
    <div className='display-flex flex-wrap gap-50'>
        <div>
            <Title>Default</Title>
            <div className='display-flex flex-wrap gap-10'>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-climate-control spinning' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-stack' />
                        <span className='rioglyph rioglyph-plus' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-trailer' />
                        <span className='rioglyph rioglyph-ok text-color-success' />
                    </span>
                </div>
            </div>
        </div>
        <div>
            <Title>With background</Title>
            <div className='display-flex flex-wrap gap-10'>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-climate-control spinning bg-darkest text-color-white' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-bus' />
                        <span className='rioglyph rioglyph-heart bg-danger text-color-white' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-trailer' />
                        <span className='rioglyph rioglyph-ok bg-success text-color-white' />
                    </span>
                </div>
            </div>
        </div>
        <div>
            <Title>Display engine types</Title>
            <div className='display-flex flex-wrap gap-10'>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-diesel' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-bus' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-van' />
                        <span className='rioglyph rioglyph-fuel-hydrogen' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-trailer' />
                        <span className='rioglyph rioglyph-fuel-gas' />
                    </span>
                </div>
            </div>
            <div className='display-flex flex-wrap gap-10 margin-top-20'>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-diesel bg-darkest text-color-white' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-bus' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-van' />
                        <span className='rioglyph rioglyph-fuel-hydrogen bg-darkest text-color-white' />
                    </span>
                </div>
                <div>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-trailer' />
                        <span className='rioglyph rioglyph-fuel-gas bg-darkest text-color-white' />
                    </span>
                </div>
            </div>
        </div>
        <div>
            <Title>Sized</Title>
            <div className='display-flex flex-wrap gap-10'>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-h2'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-h3'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-20'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-18'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-16'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-14'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric' />
                    </span>
                </div>
            </div>
            <div className='display-flex flex-wrap gap-10 margin-top-20'>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-h1'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-h2'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-h3'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-20'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-18'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-16'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
                <div className='display-flex align-items-start'>
                    <span className='rioglyph-icon-pair text-size-14'>
                        <span className='rioglyph rioglyph-truck' />
                        <span className='rioglyph rioglyph-fuel-electric bg-darkest text-color-white' />
                    </span>
                </div>
            </div>
        </div>
    </div>
);
