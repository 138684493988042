import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';
import { RioglyphCustomIcon } from './RioglyphCustomIcon';

export const RioglyphCustom = () => (
    <div className='uikit-icons'>
        <Description headline='Custom icons' headlineType='h3' anchor='customIcons' />
        <Playground example={<RioglyphCustomIcon />} />
    </div>
);
