import { NavLink } from 'react-router-dom';

import { UikitMenuSidebar } from '../../components/UikitMenuSidebar';
import { UikitMenu } from '../../components/UikitMenu';
import { routes } from '../../routes';

const base = routes.CHARTS;
const title = 'Charts';

export const getChartsMenuItems = () => {
    const menu = [
        {
            group: 'How to',
            navItems: [
                {
                    key: 'Getting started',
                    meta: 'gettingstarted',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/gettingstarted`}>
                            Getting Started
                        </NavLink>
                    ),
                },
                {
                    key: 'ChartColors',
                    meta: 'chartColors',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/chartColors`}>
                            Chart Colors
                        </NavLink>
                    ),
                },
            ],
        },
        {
            group: 'Components',
            navItems: [
                {
                    key: 'AreaCharts',
                    meta: 'areacharts',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/areacharts`}>
                            AreaCharts
                        </NavLink>
                    ),
                },
                {
                    key: 'ComposedCharts',
                    meta: 'composedcharts',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/composedcharts`}>
                            ComposedCharts
                        </NavLink>
                    ),
                },
                {
                    key: 'LineCharts',
                    meta: 'linecharts',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/linecharts`}>
                            LineCharts
                        </NavLink>
                    ),
                },
                {
                    key: 'BarCharts',
                    meta: 'barcharts',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/barcharts`}>
                            BarCharts
                        </NavLink>
                    ),
                },
                {
                    key: 'PieCharts',
                    meta: 'piecharts',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/piecharts`}>
                            PieCharts
                        </NavLink>
                    ),
                },
                {
                    key: 'RadialBarCharts',
                    meta: 'radialbarcharts',
                    item: (
                        <NavLink activeClassName='active' to={`${base}/radialbarcharts`}>
                            RadialBarCharts
                        </NavLink>
                    ),
                },
            ],
        },
    ];

    return menu.map(item => ({ ...item, badge: title }));
};

export const ChartsMenu = () => (
    <UikitMenuSidebar title={title}>
        <UikitMenu />
    </UikitMenuSidebar>
);
