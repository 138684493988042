import { PlaygroundColors } from '../../../components/playground/PlaygroundColors';
import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses, PlaygroundClassesSpacer } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';
import { colors, getColorNames } from '../../../utils/colors';

export const Badges = () => (
    <div>
        <Description headline='Badges' anchor='badges' />
        <Playground
            example={badgesExample}
            classes={badgesClasses}
            availableColors={<PlaygroundColors bgDefault bgPrimary bgSecondary bgSuccess bgWarning bgDanger bgMuted />}
        />
    </div>
);

const eachColor = ['default', ...getColorNames(colors.brand, colors.status), 'muted'];

const badgesExample = (
    <div>
        <p>
            Unlike labels, which are used for categorization and emphasis,{' '}
            <b>badges provide quick contextual information</b> in a compact and visually distinct manner.
        </p>
        <p>
            Use badges for <b>numeric indicators</b>, such as showing counts (e.g., unread messages, notifications, cart
            items). They are also useful for <b>small floating highlights</b> on UI elements (e.g., "New" or "Beta").
            Badges are commonly placed on icons, buttons, menus, or navigation elements.
        </p>
        <ul className='nav nav-pills margin-top-25'>
            <li className='active'>
                <span>
                    <span>Home</span>
                    <span className='badge badge-primary margin-left-5'>1</span>
                </span>
            </li>
            <li>
                <span>Profile</span>
            </li>
            <li>
                <span>
                    Messages
                    <span className='badge badge-muted margin-left-5'>3</span>
                </span>
            </li>
        </ul>
        <div className='padding-10'>
            <div className='padding-top-20 display-flex flex-wrap gap-10'>
                {eachColor.map((color, index) => (
                    <span className={`badge badge-${color}`} key={index}>
                        1
                    </span>
                ))}
            </div>

            <div className='padding-top-20 display-flex flex-wrap gap-10'>
                {eachColor.map((color, index) => (
                    <span className={`badge badge-lg badge-${color}`} key={index}>
                        7
                    </span>
                ))}
            </div>

            <div className='padding-top-20 display-flex flex-wrap gap-10'>
                {eachColor.map((color, index) => (
                    <span className={`badge badge-xl badge-${color}`} key={index}>
                        13
                    </span>
                ))}
            </div>

            <Title className='margin-top-25'>Badges on Icons</Title>
            <div className='display-flex flex-wrap gap-20'>
                {eachColor.map((color, index) => (
                    <span className='indicated-icon' key={index}>
                        <span className='rioglyph rioglyph-chat text-color-darker' />
                        <span className={`badge badge-indicator badge-indicator-border badge-${color}`} />
                    </span>
                ))}

                {eachColor.map((color, index) => (
                    <span className='indicated-icon' key={index}>
                        <span className='rioglyph rioglyph-chat text-color-darker' />
                        <span className={`badge badge-indicator badge-indicator-border badge-${color}`}>23</span>
                    </span>
                ))}
            </div>

            <Title className='margin-top-25'>Badges as Indicator</Title>
            <div className='display-flex flex-wrap gap-25 margin-bottom-25'>
                <div className='indicated-button'>
                    <button type='button' className='btn btn-primary'>
                        Inbox
                    </button>
                    <span className='badge badge-primary badge-indicator badge-indicator-border'>3</span>
                </div>
                <div className='indicated-button'>
                    <button type='button' className='btn btn-primary'>
                        Inbox
                    </button>
                    <span className='badge badge-danger badge-indicator badge-indicator-border badge-indicator-pinging'>
                        99+
                    </span>
                </div>
            </div>
            <div className='display-flex flex-wrap gap-25'>
                {eachColor.map((color, index) => (
                    <div className='indicated-button' key={index}>
                        <button type='button' className='btn btn-default btn-icon-only'>
                            <span className='rioglyph rioglyph-notification' />
                        </button>
                        <span className={`badge badge-${color} badge-indicator badge-indicator-pinging`} />
                    </div>
                ))}
            </div>
        </div>
    </div>
);

const badgesClasses = (
    <>
        <PlaygroundClasses label='Colors' prefix='badge-' classes={eachColor} />
        <PlaygroundClassesSpacer label='Indicator' />
        <PlaygroundClasses label='Wrapper' prefix='indicated-' classes={['button', 'icon']} />
        <PlaygroundClasses label='Badge' classes='badge-indicator' />
        <PlaygroundClasses label='Border' classes='badge-indicator-border' />
        <PlaygroundClasses label='Pinging' classes='badge-indicator-pinging' />
    </>
);
