import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';
import { Note } from '../../../components/Note';
import packageJson from '../../../../../package.json';

import { icons, getRandomIcon } from '../../../utils/icons';
import { getNames } from '../../../utils/mapUtils';
import { colors, getColorNames } from '../../../utils/colors';
import { sizes, getSizeClasses } from '../../../utils/sizes';

const humanIcons = [...getNames(icons.humans)] as string[];

const eachColor = getColorNames(colors.brand, colors.status) as string[];
const eachTextSize = getSizeClasses(sizes.text) as string[];

const CDN_URL = 'https://uikit.developers.rio.cloud';

export const Rioglyph = () => (
    <div className='uikit-icons'>
        <Description headline='RIOglyph' anchor='rioglyph' />
        <Playground example={rioglyphIconsExample} />
        <Note label='Download' type='success'>
            Download all RIOglyph icons as SVG images{' '}
            <a href={`${CDN_URL}/${packageJson.version}/rioglyph.zip`} target='_blank' rel='noreferrer'>
                here.
            </a>
        </Note>
    </div>
);

const rioglyphIconsExample = (
    <div className='display-flex flex-wrap gap-50'>
        <div>
            <Title>Default</Title>
            <div className='display-flex flex-wrap gap-10'>
                {humanIcons.map(icon => (
                    <span className={`rioglyph ${icon} text-size-h1`} key={icon} />
                ))}
            </div>
        </div>
        <div>
            <Title>Sized icons</Title>
            <div className='display-flex flex-wrap gap-10'>
                {eachTextSize.map(size => (
                    <span className={`rioglyph rioglyph-user ${size}`} key={size} />
                ))}
            </div>
        </div>
        <div>
            <Title>Colored icons</Title>
            <div className='display-flex flex-wrap gap-10'>
                {eachColor.map(color => (
                    <span className={`rioglyph ${getRandomIcon()} text-color-${color} text-size-h1`} key={color} />
                ))}
            </div>
        </div>
    </div>
);
