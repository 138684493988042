/* eslint-disable prefer-arrow/prefer-arrow-functions */
// eslint-disable-next-line max-len
// Inspired from https://github.com/mantinedev/mantine/blob/master/src/mantine-hooks/src/use-click-outside/use-click-outside.ts

import { useEffect, useRef } from 'react';

export type EventListenerType = keyof DocumentEventMap;

export const DEFAULT_EVENT_TYPES: EventListenerType[] = ['mousedown', 'touchstart'];

/**
 * Custom hook to detect clicks outside a specified element.
 *
 * @param callback - The callback function to be called when a click outside the element is detected.
 * @param events - Array of event types to listen for.
 * @param isActive - Flag to only listen for clicks when target element active.
 *
 * @returns A mutable ref object representing the target element.
 */
const useClickOutside = <T extends HTMLElement = any>(
    callback: (event: MouseEvent | TouchEvent) => void,
    events: EventListenerType[] = DEFAULT_EVENT_TYPES,
    isActive = true // Only listen when active
): React.MutableRefObject<T> => {
    const targetRef = useRef<any>(null);

    useClickOutsideWithRef(targetRef, callback, events, isActive);

    return targetRef;
};

// Internal use for now - extend it later to be used outside
export const useClickOutsideWithRef = <T extends HTMLElement = any>(
    ref: React.MutableRefObject<HTMLElement | null> | HTMLElement | null,
    callback: (event: MouseEvent | TouchEvent) => void,
    events: EventListenerType[] = DEFAULT_EVENT_TYPES,
    isActive = true
): void => {
    useEffect(() => {
        if (!isActive) {
            return; // Simply return early if not active, no cleanup needed
        }

        // In case the ref is actually a state and will be set via the state setter function
        const element = ref && 'current' in ref ? ref.current : ref;

        // Event listener function to check if the click is outside the target element
        const listener = (event: Event) => {
            if (element && !element.contains(event.target as Node)) {
                callback(event as MouseEvent | TouchEvent);
            }
        };

        // Add event listeners for each specified event type
        events.forEach(eventType => document.addEventListener(eventType, listener));

        // Cleanup function to remove event listeners
        return () => {
            events.forEach(eventType => document.removeEventListener(eventType, listener));
        };
    }, [ref, callback, events, isActive]);
};

export default useClickOutside;
