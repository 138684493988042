import React, { useState, useRef, useEffect, forwardRef, type ForwardedRef } from 'react';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

import NumberInput, {
    DEFAULT_STEP,
    DEFAULT_MIN,
    DEFAULT_MAX,
    DEFAULT_VALUE,
    convertNonIntegerToDefault,
    type NumberInputProps,
} from '../numberInput/NumberInput';

const INITIAL_TICK = 700;
const TICK_TIME = 50;

const DEFAULT_DIGIT_PRECISION = 3;

export type NumberControlProps = NumberInputProps;

const NumberControl = forwardRef((props: NumberControlProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
        id,
        min = DEFAULT_MIN,
        max = DEFAULT_MAX,
        step = DEFAULT_STEP,
        value,
        onChange,
        onValueChanged = () => {},
        onKeyDown = noop,
        disabled,
        bsSize,
        className,
        unit,
        inputAddon,
        errorMessage,
        warningMessage,
        messageWhiteSpace,
        digitPrecision = DEFAULT_DIGIT_PRECISION,
        placeholder,
        noDefault,
        ...remainingProps
    } = props;

    // Note, "onChange" should replace "onValueChanged" in the future but it's widely used
    const callback = onChange || onValueChanged;

    const timeout = useRef<NodeJS.Timeout | null>(null);

    const [isHoldingDownInc, setIsHoldingDownInc] = useState(false);
    const [isHoldingDownDec, setIsHoldingDownDec] = useState(false);
    const [internalValue, setInternalValue] = useState(value);

    const initialTimeout = (callbackFn: VoidFunction) => {
        if (timeout.current !== null) {
            clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
            callbackFn();
        }, INITIAL_TICK);
    };

    const recursiveTimeout = (callbackFn: VoidFunction) => {
        timeout.current = setTimeout(() => {
            callbackFn();
        }, TICK_TIME);
    };

    // Update internal state if external value has changed
    useEffect(() => {
        if (internalValue !== value) {
            setInternalValue(value);
        }
    }, [value]);

    // Notify external component if internal value has changed
    useEffect(() => {
        if (internalValue !== value) {
            callback(internalValue);
        }
    }, [internalValue]);

    useEffect(() => {
        // Call increment function for a loop when button is holding down
        if (isHoldingDownInc && !disabled) {
            initialTimeout(incrementRecursively);
        }

        // Call decrement function for a loop when button is holding down
        if (isHoldingDownDec && !disabled) {
            initialTimeout(decrementRecursively);
        }
    }, [isHoldingDownInc, isHoldingDownDec]);

    const incrementInternalValue = () => {
        setInternalValue(val => {
            const currentValue = convertNonIntegerToDefault(val, DEFAULT_VALUE);
            const newValue = Number((currentValue + step).toFixed(digitPrecision));
            const newValueLimited = newValue <= max ? newValue : val;
            return newValueLimited;
        });
    };

    const decrementInternalValue = () => {
        setInternalValue(val => {
            const currentValue = convertNonIntegerToDefault(val, DEFAULT_VALUE);
            const newValue = Number((currentValue - step).toFixed(digitPrecision));
            const newValueLimited = newValue >= min ? newValue : val;
            return newValueLimited;
        });
    };

    const incrementRecursively = () => {
        incrementInternalValue();
        recursiveTimeout(incrementRecursively);
    };

    const decrementRecursively = () => {
        decrementInternalValue();
        recursiveTimeout(decrementRecursively);
    };

    const handleMouseDownOnIncrement = () => {
        if (disabled) {
            return;
        }
        setIsHoldingDownInc(true);

        // increment for first click
        incrementInternalValue();
    };

    const handleMouseDownOnDecrement = () => {
        if (disabled) {
            return;
        }
        setIsHoldingDownDec(true);

        // decrement for first click
        decrementInternalValue();
    };

    const handleStopHolding = () => {
        if (timeout.current !== null) {
            clearTimeout(timeout.current);
        }
        setIsHoldingDownInc(false);
        setIsHoldingDownDec(false);
    };

    const handleUpdatedNumberInputValue = (newValue: number | undefined) => {
        // Set the internal value when the value of the actual input has changed,
        // for instance the user has typed in a number manually and to
        // use this number as base to increase or decrease from.
        // Ignore empty value in case the user has removed it
        if (newValue !== undefined && !(isHoldingDownDec && isHoldingDownInc)) {
            setInternalValue(Number(newValue));
        }
        callback(newValue);
    };

    const classes = classNames('NumberControl', className);

    const controls = (
        <div className='display-flex padding-left-10' onMouseOut={handleStopHolding} onBlur={handleStopHolding}>
            <div
                onMouseDown={handleMouseDownOnDecrement}
                onMouseUp={handleStopHolding}
                role='button'
                aria-label='decrement-button'
                className='decrementButton display-flex align-items-center text-color-gray hover-text-color-dark cursor-pointer'
            >
                <div className='rioglyph rioglyph-minus scale-90' />
            </div>
            <div
                onMouseDown={handleMouseDownOnIncrement}
                onMouseUp={handleStopHolding}
                role='button'
                aria-label='increment-button'
                className='incrementButton display-flex align-items-center text-color-gray hover-text-color-dark cursor-pointer margin-left-5'
            >
                <div className='rioglyph rioglyph-plus scale-90' />
            </div>
        </div>
    );

    return (
        <div {...remainingProps} className={classes}>
            <NumberInput
                id={id}
                ref={ref}
                min={min}
                max={max}
                value={internalValue}
                step={step}
                bsSize={bsSize}
                disabled={disabled}
                inputAddon={inputAddon}
                errorMessage={errorMessage}
                warningMessage={warningMessage}
                messageWhiteSpace={messageWhiteSpace}
                controls={controls}
                unit={unit}
                onChange={handleUpdatedNumberInputValue}
                onKeyDown={onKeyDown}
                digitPrecision={digitPrecision}
                placeholder={placeholder}
                noDefault={noDefault}
            />
        </div>
    );
});

export default NumberControl;
