import React from 'react';

import { Playground } from '../../../components/playground/Playground';
import { PlaygroundClasses, PlaygroundResponsiveClasses } from '../../../components/playground/PlaygroundClasses';
import { Description } from '../../../components/Description';
import { Title } from '../../../components/Title';

export const TextWrapping = () => (
    <div>
        <Description headline='Text wrapping' anchor='text-wrapping' />
        <Playground example={textWrappingExample} classes={textWrappingClasses} />
    </div>
);

const textWrappingExample = (
    <div className='display-flex flex-wrap gap-25'>
        <div className='width-250 min-width-250'>
            <Title>Word break</Title>
            <div className='word-break-all text-size-20'>
                Finanzmarktstabilisierungsbeschleunigungsgesetzes sowie § 5 Absatz 2 und 5 der
                Finanzmarktstabilisierungsfondsverordnung in der am 23. Juli 2009 geltenden Fassung gelten für die
                Garantiegewährung nach Absatz 1 entsprechend.
            </div>
        </div>
        <div className='width-250 min-width-250'>
            <Title>Hyphens</Title>
            <div className='hyphens-auto text-size-20'>
                Finanzmarktstabilisierungsbeschleunigungsgesetzes sowie § 5 Absatz 2 und 5 der
                Finanzmarktstabilisierungsfondsverordnung in der am 23. Juli 2009 geltenden Fassung gelten für die
                Garantiegewährung nach Absatz 1 entsprechend.
            </div>
        </div>
        <div className='max-width-500'>
            <Title caniuseLink='https://caniuse.com/css-text-wrap-balance'>Balance</Title>
            <div className='text-wrap-balance text-size-20 text-center'>
                Allows text to have it's lines broken that each line is roughly the same width.
            </div>
        </div>
        <div className='max-width-500'>
            <Title caniuseLink='https://caniuse.com/css-text-wrap-pretty'>Pretty</Title>
            <div className='text-wrap-pretty text-size-20 text-center'>
                It evaluate the last four lines in a text block to work out adjustments as needed to ensure the last
                line has two or more words.
            </div>
        </div>
    </div>
);

const textWrappingClasses = (
    <>
        <PlaygroundClasses label='Word break' classes={['word-break-all', 'word-break-none']} />
        <PlaygroundClasses label='Hyphens' classes={['hyphens-auto', 'hyphens-none']} />
        <PlaygroundClasses label='White-space' prefix='white-space-' classes={['nowrap', 'pre-line', 'normal']} />
        <PlaygroundClasses label='Text-wrap' classes={['text-wrap-balance', 'text-wrap-pretty']} />
        <PlaygroundResponsiveClasses />
    </>
);
