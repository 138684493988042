import { useState } from 'react';
import { Link } from 'react-router-dom';

import ClearableInput from '../../../../../src/ClearableInput';

const questionMarkIcon = 'https://cdn.rio.cloud/svg/rioglyph/question-sign.svg';

export const RioglyphCustomIcon = () => {
    const [customIcon, setCustomIcon] = useState('https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg');

    const handleCustomIcon = (
        newValue: string,
        event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLSpanElement>
    ) => {
        setCustomIcon(newValue);
    };

    return <RioglyphCustomIconPreview customIcon={customIcon} handleCustomIcon={handleCustomIcon} />;
};

const RioglyphCustomIconPreview = ({
    customIcon,
    handleCustomIcon,
}: {
    customIcon: string;
    handleCustomIcon: (
        newValue: string,
        event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLSpanElement>
    ) => void;
}) => {
    return (
        <div>
            <p>
                Use external svgs as RIOglyph icons. Please read our{' '}
                <Link to='/start/guidelines/custom-rioglyph'>guidelines</Link>
            </p>
            <div className='display-flex gap-10'>
                <div className='flex-1-1'>
                    <div className='input-group width-100pct'>
                        <span className='input-group-addon'>
                            <span
                                className='rioglyph text-color-primary'
                                aria-hidden='true'
                                style={
                                    {
                                        '--i': `url(${customIcon || questionMarkIcon})`,
                                    } as React.CSSProperties
                                }
                            />
                        </span>
                        <ClearableInput value={customIcon} placeholder='Enter icon URL' onChange={handleCustomIcon} />
                    </div>
                </div>
            </div>
        </div>
    );
};
