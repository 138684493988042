import { useState } from 'react';

import { Playground } from '../../../components/playground/Playground';
import { Description } from '../../../components/Description';
import Switch from '../../../../../src/Switch';

import ClearableInput from '../../../../../src/ClearableInput';
import FormLabel from '../../../../../src/FormLabel';
import { rioglyphIcons } from '../../../../../rioglyph/generated/RioglyphPreview';
import { iconsMetaData } from '../../../../../rioglyph/generated/rioglyphMetaData';
import { isProd } from '../../../../../src/utils/init/initConfig';

export const RioglyphLibrary = () => (
    <div className='uikit-icons'>
        <Description headline='Icon library' anchor='iconLibrary' />
        <Playground example={<RioglyphLibraryExample />} />
    </div>
);

const RioglyphLibraryExample = () => {
    const [icons, setIcons] = useState<string[]>(rioglyphIcons ?? []);
    const [filterValue, setFilterValue] = useState('');
    const [filledIcons, setFilledIcons] = useState(false);

    const handleFilterIcons = (value: string) => {
        const filterByNameResult = rioglyphIcons.filter(icon => {
            return icon.includes(value) || iconsMetaData[icon]?.toLowerCase().includes(value.toLowerCase());
        });
        setIcons(filterByNameResult);
        setFilterValue(value);
    };

    const handleFilledSwitch = () => {
        setFilledIcons(prevState => !prevState);
    };

    return (
        <>
            <div className='display-flex gap-20 margin-bottom-15'>
                <div className='max-width-300 flex-1-1'>
                    <FormLabel htmlFor='iconNameFilter'>Search</FormLabel>
                    <div className='input-group width-100pct'>
                        <span className='input-group-addon'>
                            <span className='rioglyph rioglyph-search' aria-hidden='true' />
                        </span>
                        <ClearableInput
                            id='iconNameFilter'
                            value={filterValue}
                            placeholder='Filter by icon name'
                            onChange={handleFilterIcons}
                        />
                    </div>
                </div>
                {!isProd && (
                    <div>
                        <FormLabel>Filled icons</FormLabel>
                        <Switch
                            onChange={handleFilledSwitch}
                            checked={filledIcons}
                            minWidth={50}
                            enabledText='On'
                            disabledText='OFF'
                        />
                    </div>
                )}
            </div>
            <div className='display-flex flex-wrap'>
                {icons?.map(icon => (
                    <div
                        key={icon}
                        className='rioglyph-preview display-flex align-items-center margin-top-10 margin-bottom-10'
                    >
                        <span
                            className={`rioglyph ${icon} ${filledIcons ? 'rioglyph-filled' : ''} text-size-h3 margin-right-10`}
                        />
                        <span className='text-light user-select-all'>{`rioglyph ${icon} ${filledIcons ? 'rioglyph-filled' : ''}`}</span>
                    </div>
                ))}
            </div>
        </>
    );
};
