// @ts-ignore-next-line importsNotUsedAsValues
import React from 'react';

import type { SelectOption } from './Select';

export type SelectedOptionProps = {
    label: string | React.ReactNode;
    placeholder?: string | React.ReactNode;
    selectedItem: SelectOption | null;
    showSelectedItemIcon: boolean;
    showUnselectedItemIcons: boolean;
    options: SelectOption[];
};

const SelectedOption = (props: SelectedOptionProps) => {
    const { label, placeholder, selectedItem, options, showSelectedItemIcon, showUnselectedItemIcons } = props;

    const renderUnselectedItemIcons = (optionsToRender: SelectOption[], currentSelectedItem: SelectOption | null) => {
        const optionSpans = optionsToRender
            .filter(option => !option.header)
            .map(option => (
                <span
                    key={option.id}
                    className={`${currentSelectedItem && currentSelectedItem.id === option.id ? '' : 'inactiveIcon'}`}
                >
                    {option.icon}
                </span>
            ));

        return <>{optionSpans}</>;
    };

    const getSelectedItemLabel = (
        item: SelectOption | null,
        placeholderValue: string | React.ReactNode | undefined
    ) => {
        if (showUnselectedItemIcons) {
            return renderUnselectedItemIcons(options, item);
        }

        if (!item) {
            // render a placeholder or if there is none render a non-breaking space "&nbsp;"
            return placeholderValue ? <span className='placeholder'>{placeholderValue}</span> : '\u00a0';
        }

        return (
            <>
                {item.icon && <span>{item.icon}</span>}
                {!showSelectedItemIcon && item.label}
            </>
        );
    };

    return (
        <span className='selected-option-text'>
            {label && <span className='selected-option-text-label'>{label}:</span>}
            {getSelectedItemLabel(selectedItem, placeholder)}
        </span>
    );
};

export default SelectedOption;
