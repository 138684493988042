// @ts-ignore-next-line importsNotUsedAsValues
import React, { type ReactNode } from 'react';
import classNames from 'classnames';

import ListMenuHeader from './ListMenuHeader';

export type ListMenuNavItem = {
    /**
     * The name or key of the menu nav item.
     *
     * This key will be used for filtering, hence it should match the translated menu item name.
     */
    key: string;

    /**
     * The menu nav item itself.
     *
     * This can be a simple `a`, `NavLink`, `Link`, `span` or even a `FormattedMessage`.
     */
    item: string | ReactNode;

    /**
     * Disables the list item.
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * Whether the current menu item is a child of another menu item.
     */
    isSubItem?: boolean;
} & {
    [otherFilterKey: Exclude<string, 'key' | 'item' | 'disabled' | 'isSubItem'>]: unknown;
};

export type ListMenuItem<T extends ListMenuNavItem = ListMenuNavItem> = {
    /**
     * The optional group header to be shown.
     *
     * This can also be a component such as a `FormattedMessage`.
     */
    group?: string | ReactNode;

    /**
     * The optional group header link to be shown.
     *
     * This can be a link component such as `NavLink` or `Link`. This way, the header can be a link itself. In this
     * case, the `group` prop can be omitted.
     */
    groupNavItem?: string | ReactNode;

    /**
     * The optional badge displayed alongside the group or nav items.
     *
     * This can be text or a React node for additional flexibility.
     */
    badge?: string | ReactNode;

    /**
     * The type of badge to display.
     *
     * Determines the visual style of the badge. Available types include:
     * - `muted`: A subdued badge style.
     * - `success`: Indicates a successful state.
     * - `info`: Represents informational content.
     * - `warning`: Warns of potential issues.
     * - `danger`: Highlights a critical state.
     */
    badgeType?: 'muted' | 'success' | 'info' | 'warning' | 'danger';

    /**
     * The list of all menu items of a group.
     */
    navItems: T[];
};

type ListMenuGroupProps<T extends ListMenuNavItem> = {
    menuGroup: ListMenuItem<T>;
    className: string;
};

const ListMenuGroup = <T extends ListMenuNavItem>({ menuGroup, className }: ListMenuGroupProps<T>) => {
    if (!menuGroup.navItems.length) {
        return null;
    }

    return (
        <ul className={`ListMenuGroup ${className}`}>
            <ListMenuHeader {...menuGroup} />

            {menuGroup.navItems.map(item => (
                <li key={item.key} className={classNames({ disabled: item.disabled, subitem: item.isSubItem })}>
                    {item.item}
                </li>
            ))}
        </ul>
    );
};

export default ListMenuGroup;
